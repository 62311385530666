

html {

    height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;
   }
   
   
   body {
     scroll-behavior: smooth;
     font-family: "Poppins", sans-serif;
     margin: 0;
     padding: 0;
     width: 100%;
     height: 100%;
     
   }
   
   h1, h2, h3, h4, h5, h6, p{
     margin: 0;
     padding: 0;
   }
   ul, ol{
     margin: 0;
     padding: 0;
     list-style: none;
   }
   a{
     color: inherit;
     text-decoration: none;
   }
   a:hover, a:focus{
     color: inherit;
     text-decoration: none;
   }
   .btn.focus, .btn:focus {
     outline: 0;
     box-shadow: none;
   }
   button:focus {
     outline: 0;
     box-shadow: none;
   }
   
   .welcome-content{
       position: absolute;
       top:7%;
       width: 90%;
       text-align: center;
       margin: auto;
       left: 50%;
       transform: translateX(-50%);
   }
   
   
   h1.sweep-welcome-heading {
       font-size: 40px;
       text-transform: uppercase;
       color: #009933;
       text-align: center;
       letter-spacing: 1px;
       position: relative;
       margin-bottom: 15px;
   }
   h1.sweep-welcome-heading::after {
       content: '';
       position: absolute;
       bottom: -3px;
       left: 50%;
       height: 2px;
       width: 150px;
       background-color: white;
       transform: translateX(-50%);
       border-radius: 50%;
   }
   
   p.sweep-welcome-para1, p.sweep-upi-para {
       font-size: 25px;
       color: black;
       margin-bottom: 3rem;
   }
   
    p.scan-upi-para {
     font-size: 25px;
     color: black;
     margin-bottom: 1rem;
   }
   
   .sweep-gpay {
       width: 100%;
   }
   
   .sweep-input-wrapper {
       display: flex;
       justify-content: center;
       align-items: center;
       width: 100%;
   }
   .sweep-input-wrapper input.form-control {
       width: 70%;
   }
   
   .sweep-input-wrapper select.form-select {
       width: 30%;
       margin-left: 5px;
   }
   
   button.btn.sweep-submit{
       font-size: 20px;
       margin: 1rem 0;
       padding: 8px 20px;
   }
   
   
   
   
   /* Thank you page */
   
   main.sweep-thnku {
       background: #ffffff;
       min-width: 320px;
       max-width: 480px;
       width: 100%;
       display: block;
       margin: auto;
       border: 0 none;
       height: 100%;
       position: relative;
       overflow: hidden;
   }
   section.thnk-page {
       min-height: 100%;
       position: relative;
       background-repeat: no-repeat !important;
       background-size: cover !important;
       background-position: top !important;
       text-align: center;
       height: auto;
   }
   .sweep-thnku-bg {
       width: 100%;
       height: 100vh;
       overflow: hidden;
       object-fit: cover;
   }
   
   .thnku-content {
       position: absolute;
       top: 15%;
       width: 100%;
       text-align: center;
   }
    img.sweep-logo {
       width: 170px;
   }
   .sweep-thnku-text {
       margin-top: 4rem;
   }
   
   .confetti {
       position: absolute;
       width: 10px;
       height: 10px;
       background-color: #ffcc00;
       opacity: 0;
       animation: confettiAnimation 3s infinite;
       top: 0;
     }
   
   
   
   
     @keyframes confettiAnimation {
       0% {
         opacity: 0;
         transform: translateY(0);
       }
       100% {
         opacity: 1;
         transform: translateY(100vh);
       }
     }
   
   
   
   
   
     [class|=confetti] {
       position: absolute;
       z-index: 99;
       }
       
       .confetti-0 {
       width: 5px;
       height: 2px;
       background-color: #ffbf00;
       top: -10%;
       left: 33%;
       opacity: 1.3202434448;
       transform: rotate(40.2463283311deg);
       -webkit-animation: drop-0 4.00788746s 0.6050063989s infinite;
               animation: drop-0 4.00788746s 0.6050063989s infinite;
       }
       
       @-webkit-keyframes drop-0 {
       100% {
         top: 110%;
         left: 38%;
       }
       }
       
       @keyframes drop-0 {
       100% {
         top: 110%;
         left: 38%;
       }
       }
       .confetti-1 {
       width: 2px;
       height: 0.8px;
       background-color: #ffbf00;
       top: -10%;
       left: 6%;
       opacity: 1.0197218672;
       transform: rotate(71.920755702deg);
       -webkit-animation: drop-1 4.7022674111s 0.1261345896s infinite;
               animation: drop-1 4.7022674111s 0.1261345896s infinite;
       }
       
       @-webkit-keyframes drop-1 {
       100% {
         top: 110%;
         left: 20%;
       }
       }
       
       @keyframes drop-1 {
       100% {
         top: 110%;
         left: 20%;
       }
       }
       .confetti-2 {
       width: 3px;
       height: 1.2px;
       background-color: #263672;
       top: -10%;
       left: 29%;
       opacity: 0.8118035305;
       transform: rotate(72.7259131773deg);
       -webkit-animation: drop-2 4.6005602953s 0.9783159869s infinite;
               animation: drop-2 4.6005602953s 0.9783159869s infinite;
       }
       
       @-webkit-keyframes drop-2 {
       100% {
         top: 110%;
         left: 39%;
       }
       }
       
       @keyframes drop-2 {
       100% {
         top: 110%;
         left: 39%;
       }
       }
       .confetti-3 {
       width: 7px;
       height: 2.8px;
       background-color: #d13447;
       top: -10%;
       left: 15%;
       opacity: 0.9967266418;
       transform: rotate(102.1816428606deg);
       -webkit-animation: drop-3 4.0797847677s 0.6803048788s infinite;
               animation: drop-3 4.0797847677s 0.6803048788s infinite;
       }
       
       @-webkit-keyframes drop-3 {
       100% {
         top: 110%;
         left: 30%;
       }
       }
       
       @keyframes drop-3 {
       100% {
         top: 110%;
         left: 30%;
       }
       }
       .confetti-4 {
       width: 4px;
       height: 1.6px;
       background-color: #ffbf00;
       top: -10%;
       left: 35%;
       opacity: 0.9212695523;
       transform: rotate(234.2745316634deg);
       -webkit-animation: drop-4 4.0809231819s 0.7421063258s infinite;
               animation: drop-4 4.0809231819s 0.7421063258s infinite;
       }
       
       @-webkit-keyframes drop-4 {
       100% {
         top: 110%;
         left: 43%;
       }
       }
       
       @keyframes drop-4 {
       100% {
         top: 110%;
         left: 43%;
       }
       }
       .confetti-5 {
       width: 5px;
       height: 2px;
       background-color: #263672;
       top: -10%;
       left: 15%;
       opacity: 1.1531113414;
       transform: rotate(161.6705227453deg);
       -webkit-animation: drop-5 4.7838282722s 0.1796326151s infinite;
               animation: drop-5 4.7838282722s 0.1796326151s infinite;
       }
       
       @-webkit-keyframes drop-5 {
       100% {
         top: 110%;
         left: 27%;
       }
       }
       
       @keyframes drop-5 {
       100% {
         top: 110%;
         left: 27%;
       }
       }
       .confetti-6 {
       width: 6px;
       height: 2.4px;
       background-color: #263672;
       top: -10%;
       left: 77%;
       opacity: 1.3771139412;
       transform: rotate(36.6276476039deg);
       -webkit-animation: drop-6 4.5441813999s 0.2856592948s infinite;
               animation: drop-6 4.5441813999s 0.2856592948s infinite;
       }
       
       @-webkit-keyframes drop-6 {
       100% {
         top: 110%;
         left: 83%;
       }
       }
       
       @keyframes drop-6 {
       100% {
         top: 110%;
         left: 83%;
       }
       }
       .confetti-7 {
       width: 7px;
       height: 2.8px;
       background-color: #ffbf00;
       top: -10%;
       left: 50%;
       opacity: 1.1944068423;
       transform: rotate(125.0653776223deg);
       -webkit-animation: drop-7 4.9578502309s 0.0763053588s infinite;
               animation: drop-7 4.9578502309s 0.0763053588s infinite;
       }
       
       @-webkit-keyframes drop-7 {
       100% {
         top: 110%;
         left: 60%;
       }
       }
       
       @keyframes drop-7 {
       100% {
         top: 110%;
         left: 60%;
       }
       }
       .confetti-8 {
       width: 6px;
       height: 2.4px;
       background-color: #ffbf00;
       top: -10%;
       left: 94%;
       opacity: 1.1188684956;
       transform: rotate(64.1711498041deg);
       -webkit-animation: drop-8 4.179453089s 0.2311027327s infinite;
               animation: drop-8 4.179453089s 0.2311027327s infinite;
       }
       
       @-webkit-keyframes drop-8 {
       100% {
         top: 110%;
         left: 98%;
       }
       }
       
       @keyframes drop-8 {
       100% {
         top: 110%;
         left: 98%;
       }
       }
       .confetti-9 {
       width: 4px;
       height: 1.6px;
       background-color: #ffbf00;
       top: -10%;
       left: 3%;
       opacity: 1.2029052961;
       transform: rotate(90.78871679deg);
       -webkit-animation: drop-9 4.2744658837s 0.6461935805s infinite;
               animation: drop-9 4.2744658837s 0.6461935805s infinite;
       }
       
       @-webkit-keyframes drop-9 {
       100% {
         top: 110%;
         left: 17%;
       }
       }
       
       @keyframes drop-9 {
       100% {
         top: 110%;
         left: 17%;
       }
       }
       .confetti-10 {
       width: 4px;
       height: 1.6px;
       background-color: #ffbf00;
       top: -10%;
       left: 25%;
       opacity: 0.9172690217;
       transform: rotate(305.5832177105deg);
       -webkit-animation: drop-10 4.8984568518s 0.5485274434s infinite;
               animation: drop-10 4.8984568518s 0.5485274434s infinite;
       }
       
       @-webkit-keyframes drop-10 {
       100% {
         top: 110%;
         left: 32%;
       }
       }
       
       @keyframes drop-10 {
       100% {
         top: 110%;
         left: 32%;
       }
       }
       .confetti-11 {
       width: 5px;
       height: 2px;
       background-color: #ffbf00;
       top: -10%;
       left: 10%;
       opacity: 0.7560060122;
       transform: rotate(13.9522179118deg);
       -webkit-animation: drop-11 4.6203586863s 0.1949483933s infinite;
               animation: drop-11 4.6203586863s 0.1949483933s infinite;
       }
       
       @-webkit-keyframes drop-11 {
       100% {
         top: 110%;
         left: 21%;
       }
       }
       
       @keyframes drop-11 {
       100% {
         top: 110%;
         left: 21%;
       }
       }
       .confetti-12 {
       width: 2px;
       height: 0.8px;
       background-color: #ffbf00;
       top: -10%;
       left: 49%;
       opacity: 0.6075255585;
       transform: rotate(9.7196128937deg);
       -webkit-animation: drop-12 4.7287966795s 0.8300035484s infinite;
               animation: drop-12 4.7287966795s 0.8300035484s infinite;
       }
       
       @-webkit-keyframes drop-12 {
       100% {
         top: 110%;
         left: 64%;
       }
       }
       
       @keyframes drop-12 {
       100% {
         top: 110%;
         left: 64%;
       }
       }
       .confetti-13 {
       width: 8px;
       height: 3.2px;
       background-color: #ffbf00;
       top: -10%;
       left: 31%;
       opacity: 1.3557586589;
       transform: rotate(255.5462923458deg);
       -webkit-animation: drop-13 4.6707591736s 0.7662715736s infinite;
               animation: drop-13 4.6707591736s 0.7662715736s infinite;
       }
       
       @-webkit-keyframes drop-13 {
       100% {
         top: 110%;
         left: 36%;
       }
       }
       
       @keyframes drop-13 {
       100% {
         top: 110%;
         left: 36%;
       }
       }
       .confetti-14 {
       width: 4px;
       height: 1.6px;
       background-color: #263672;
       top: -10%;
       left: 76%;
       opacity: 0.870295072;
       transform: rotate(46.8429821265deg);
       -webkit-animation: drop-14 4.9550860575s 0.5566500851s infinite;
               animation: drop-14 4.9550860575s 0.5566500851s infinite;
       }
       
       @-webkit-keyframes drop-14 {
       100% {
         top: 110%;
         left: 81%;
       }
       }
       
       @keyframes drop-14 {
       100% {
         top: 110%;
         left: 81%;
       }
       }
       .confetti-15 {
       width: 6px;
       height: 2.4px;
       background-color: #263672;
       top: -10%;
       left: 52%;
       opacity: 0.9130583058;
       transform: rotate(62.0205084772deg);
       -webkit-animation: drop-15 4.9338261684s 0.9434993503s infinite;
               animation: drop-15 4.9338261684s 0.9434993503s infinite;
       }
       
       @-webkit-keyframes drop-15 {
       100% {
         top: 110%;
         left: 63%;
       }
       }
       
       @keyframes drop-15 {
       100% {
         top: 110%;
         left: 63%;
       }
       }
       .confetti-16 {
       width: 7px;
       height: 2.8px;
       background-color: #ffbf00;
       top: -10%;
       left: 53%;
       opacity: 1.3852816829;
       transform: rotate(196.7895514588deg);
       -webkit-animation: drop-16 4.436141375s 0.8744228164s infinite;
               animation: drop-16 4.436141375s 0.8744228164s infinite;
       }
       
       @-webkit-keyframes drop-16 {
       100% {
         top: 110%;
         left: 57%;
       }
       }
       
       @keyframes drop-16 {
       100% {
         top: 110%;
         left: 57%;
       }
       }
       .confetti-17 {
       width: 2px;
       height: 0.8px;
       background-color: #263672;
       top: -10%;
       left: 23%;
       opacity: 1.2110136065;
       transform: rotate(189.0302890737deg);
       -webkit-animation: drop-17 4.3242873416s 0.0882276017s infinite;
               animation: drop-17 4.3242873416s 0.0882276017s infinite;
       }
       
       @-webkit-keyframes drop-17 {
       100% {
         top: 110%;
         left: 33%;
       }
       }
       
       @keyframes drop-17 {
       100% {
         top: 110%;
         left: 33%;
       }
       }
       .confetti-18 {
       width: 3px;
       height: 1.2px;
       background-color: #263672;
       top: -10%;
       left: 28%;
       opacity: 0.6987014698;
       transform: rotate(344.977203343deg);
       -webkit-animation: drop-18 4.9120406131s 0.7363781485s infinite;
               animation: drop-18 4.9120406131s 0.7363781485s infinite;
       }
       
       @-webkit-keyframes drop-18 {
       100% {
         top: 110%;
         left: 40%;
       }
       }
       
       @keyframes drop-18 {
       100% {
         top: 110%;
         left: 40%;
       }
       }
       .confetti-19 {
       width: 5px;
       height: 2px;
       background-color: #d13447;
       top: -10%;
       left: 85%;
       opacity: 0.7712882055;
       transform: rotate(313.6778036985deg);
       -webkit-animation: drop-19 4.8281627067s 0.4098711479s infinite;
               animation: drop-19 4.8281627067s 0.4098711479s infinite;
       }
       
       @-webkit-keyframes drop-19 {
       100% {
         top: 110%;
         left: 88%;
       }
       }
       
       @keyframes drop-19 {
       100% {
         top: 110%;
         left: 88%;
       }
       }
       .confetti-20 {
       width: 5px;
       height: 2px;
       background-color: #263672;
       top: -10%;
       left: 29%;
       opacity: 1.2660082682;
       transform: rotate(40.8955802444deg);
       -webkit-animation: drop-20 4.9172941688s 0.9443089992s infinite;
               animation: drop-20 4.9172941688s 0.9443089992s infinite;
       }
       
       @-webkit-keyframes drop-20 {
       100% {
         top: 110%;
         left: 40%;
       }
       }
       
       @keyframes drop-20 {
       100% {
         top: 110%;
         left: 40%;
       }
       }
       .confetti-21 {
       width: 1px;
       height: 0.4px;
       background-color: #ffbf00;
       top: -10%;
       left: 20%;
       opacity: 1.2550087302;
       transform: rotate(338.8948650793deg);
       -webkit-animation: drop-21 4.7629932104s 0.1439447512s infinite;
               animation: drop-21 4.7629932104s 0.1439447512s infinite;
       }
       
       @-webkit-keyframes drop-21 {
       100% {
         top: 110%;
         left: 33%;
       }
       }
       
       @keyframes drop-21 {
       100% {
         top: 110%;
         left: 33%;
       }
       }
       .confetti-22 {
       width: 3px;
       height: 1.2px;
       background-color: #ffbf00;
       top: -10%;
       left: 47%;
       opacity: 1.3545188616;
       transform: rotate(19.5934939844deg);
       -webkit-animation: drop-22 4.4623566879s 0.8960714594s infinite;
               animation: drop-22 4.4623566879s 0.8960714594s infinite;
       }
       
       @-webkit-keyframes drop-22 {
       100% {
         top: 110%;
         left: 61%;
       }
       }
       
       @keyframes drop-22 {
       100% {
         top: 110%;
         left: 61%;
       }
       }
       .confetti-23 {
       width: 5px;
       height: 2px;
       background-color: #d13447;
       top: -10%;
       left: 57%;
       opacity: 0.8201461881;
       transform: rotate(339.4720329407deg);
       -webkit-animation: drop-23 4.4624008941s 0.6621418217s infinite;
               animation: drop-23 4.4624008941s 0.6621418217s infinite;
       }
       
       @-webkit-keyframes drop-23 {
       100% {
         top: 110%;
         left: 61%;
       }
       }
       
       @keyframes drop-23 {
       100% {
         top: 110%;
         left: 61%;
       }
       }
       .confetti-24 {
       width: 8px;
       height: 3.2px;
       background-color: #ffbf00;
       top: -10%;
       left: 26%;
       opacity: 1.1208219094;
       transform: rotate(7.598944429deg);
       -webkit-animation: drop-24 4.0938815491s 0.0073728694s infinite;
               animation: drop-24 4.0938815491s 0.0073728694s infinite;
       }
       
       @-webkit-keyframes drop-24 {
       100% {
         top: 110%;
         left: 35%;
       }
       }
       
       @keyframes drop-24 {
       100% {
         top: 110%;
         left: 35%;
       }
       }
       .confetti-25 {
       width: 4px;
       height: 1.6px;
       background-color: #ffbf00;
       top: -10%;
       left: 11%;
       opacity: 0.9684544461;
       transform: rotate(157.7704775465deg);
       -webkit-animation: drop-25 4.9270564218s 0.0139446999s infinite;
               animation: drop-25 4.9270564218s 0.0139446999s infinite;
       }
       
       @-webkit-keyframes drop-25 {
       100% {
         top: 110%;
         left: 20%;
       }
       }
       
       @keyframes drop-25 {
       100% {
         top: 110%;
         left: 20%;
       }
       }
       .confetti-26 {
       width: 7px;
       height: 2.8px;
       background-color: #d13447;
       top: -10%;
       left: 59%;
       opacity: 1.0068453446;
       transform: rotate(207.0693793038deg);
       -webkit-animation: drop-26 4.6925829219s 0.6476220524s infinite;
               animation: drop-26 4.6925829219s 0.6476220524s infinite;
       }
       
       @-webkit-keyframes drop-26 {
       100% {
         top: 110%;
         left: 73%;
       }
       }
       
       @keyframes drop-26 {
       100% {
         top: 110%;
         left: 73%;
       }
       }
       .confetti-27 {
       width: 7px;
       height: 2.8px;
       background-color: #263672;
       top: -10%;
       left: 65%;
       opacity: 1.0140140564;
       transform: rotate(135.169089324deg);
       -webkit-animation: drop-27 4.3329162256s 0.6243912778s infinite;
               animation: drop-27 4.3329162256s 0.6243912778s infinite;
       }
       
       @-webkit-keyframes drop-27 {
       100% {
         top: 110%;
         left: 71%;
       }
       }
       
       @keyframes drop-27 {
       100% {
         top: 110%;
         left: 71%;
       }
       }
       .confetti-28 {
       width: 8px;
       height: 3.2px;
       background-color: #ffbf00;
       top: -10%;
       left: 74%;
       opacity: 0.9456359752;
       transform: rotate(298.8195940327deg);
       -webkit-animation: drop-28 4.1812341847s 0.8522335441s infinite;
               animation: drop-28 4.1812341847s 0.8522335441s infinite;
       }
       
       @-webkit-keyframes drop-28 {
       100% {
         top: 110%;
         left: 80%;
       }
       }
       
       @keyframes drop-28 {
       100% {
         top: 110%;
         left: 80%;
       }
       }
       .confetti-29 {
       width: 3px;
       height: 1.2px;
       background-color: #d13447;
       top: -10%;
       left: 100%;
       opacity: 1.4218382923;
       transform: rotate(226.6775789446deg);
       -webkit-animation: drop-29 4.7338154247s 0.582231231s infinite;
               animation: drop-29 4.7338154247s 0.582231231s infinite;
       }
       
       @-webkit-keyframes drop-29 {
       100% {
         top: 110%;
         left: 110%;
       }
       }
       
       @keyframes drop-29 {
       100% {
         top: 110%;
         left: 110%;
       }
       }
       .confetti-30 {
       width: 1px;
       height: 0.4px;
       background-color: #263672;
       top: -10%;
       left: 46%;
       opacity: 1.4105988835;
       transform: rotate(18.6160479652deg);
       -webkit-animation: drop-30 4.6588967203s 0.1431496745s infinite;
               animation: drop-30 4.6588967203s 0.1431496745s infinite;
       }
       
       @-webkit-keyframes drop-30 {
       100% {
         top: 110%;
         left: 50%;
       }
       }
       
       @keyframes drop-30 {
       100% {
         top: 110%;
         left: 50%;
       }
       }
       .confetti-31 {
       width: 1px;
       height: 0.4px;
       background-color: #263672;
       top: -10%;
       left: 84%;
       opacity: 0.6397630954;
       transform: rotate(91.8694618262deg);
       -webkit-animation: drop-31 4.0408200514s 0.5923950507s infinite;
               animation: drop-31 4.0408200514s 0.5923950507s infinite;
       }
       
       @-webkit-keyframes drop-31 {
       100% {
         top: 110%;
         left: 99%;
       }
       }
       
       @keyframes drop-31 {
       100% {
         top: 110%;
         left: 99%;
       }
       }
       .confetti-32 {
       width: 4px;
       height: 1.6px;
       background-color: #263672;
       top: -10%;
       left: 68%;
       opacity: 0.6560114695;
       transform: rotate(193.4494269768deg);
       -webkit-animation: drop-32 4.3049756142s 0.4743989403s infinite;
               animation: drop-32 4.3049756142s 0.4743989403s infinite;
       }
       
       @-webkit-keyframes drop-32 {
       100% {
         top: 110%;
         left: 74%;
       }
       }
       
       @keyframes drop-32 {
       100% {
         top: 110%;
         left: 74%;
       }
       }
       .confetti-33 {
       width: 6px;
       height: 2.4px;
       background-color: #ffbf00;
       top: -10%;
       left: 44%;
       opacity: 0.5451613871;
       transform: rotate(255.6153444641deg);
       -webkit-animation: drop-33 4.8427819505s 0.3222313153s infinite;
               animation: drop-33 4.8427819505s 0.3222313153s infinite;
       }
       
       @-webkit-keyframes drop-33 {
       100% {
         top: 110%;
         left: 52%;
       }
       }
       
       @keyframes drop-33 {
       100% {
         top: 110%;
         left: 52%;
       }
       }
       .confetti-34 {
       width: 4px;
       height: 1.6px;
       background-color: #263672;
       top: -10%;
       left: 99%;
       opacity: 0.9232250998;
       transform: rotate(71.5764733389deg);
       -webkit-animation: drop-34 4.9193548811s 0.7216941572s infinite;
               animation: drop-34 4.9193548811s 0.7216941572s infinite;
       }
       
       @-webkit-keyframes drop-34 {
       100% {
         top: 110%;
         left: 112%;
       }
       }
       
       @keyframes drop-34 {
       100% {
         top: 110%;
         left: 112%;
       }
       }
       .confetti-35 {
       width: 7px;
       height: 2.8px;
       background-color: #ffbf00;
       top: -10%;
       left: 72%;
       opacity: 1.4430565815;
       transform: rotate(41.2308572709deg);
       -webkit-animation: drop-35 4.3896376563s 0.569709174s infinite;
               animation: drop-35 4.3896376563s 0.569709174s infinite;
       }
       
       @-webkit-keyframes drop-35 {
       100% {
         top: 110%;
         left: 76%;
       }
       }
       
       @keyframes drop-35 {
       100% {
         top: 110%;
         left: 76%;
       }
       }
       .confetti-36 {
       width: 1px;
       height: 0.4px;
       background-color: #263672;
       top: -10%;
       left: 5%;
       opacity: 0.5355754548;
      
      
       transform: rotate(196.4915682429deg);
       -webkit-animation: drop-36 4.970457015s 0.0953207518s infinite;
               animation: drop-36 4.970457015s 0.0953207518s infinite;
       }
       
       @-webkit-keyframes drop-36 {
       100% {
         top: 110%;
         left: 10%;
       }
       }
       
       @keyframes drop-36 {
       100% {
         top: 110%;
         left: 10%;
       }
       }
       .confetti-37 {
       width: 1px;
       height: 0.4px;
       background-color: #263672;
       top: -10%;
       left: 77%;
       opacity: 0.8304900024;
       transform: rotate(102.8451060165deg);
       -webkit-animation: drop-37 4.0148241662s 0.6055869842s infinite;
               animation: drop-37 4.0148241662s 0.6055869842s infinite;
       }
       
       @-webkit-keyframes drop-37 {
       100% {
         top: 110%;
         left: 84%;
       }
       }
       
       @keyframes drop-37 {
       100% {
         top: 110%;
         left: 84%;
       }
       }
       .confetti-38 {
       width: 8px;
       height: 3.2px;
       background-color: #ffbf00;
       top: -10%;
       left: 48%;
       opacity: 1.1838133044;
       transform: rotate(186.6903153563deg);
       -webkit-animation: drop-38 4.3042356252s 0.450503913s infinite;
               animation: drop-38 4.3042356252s 0.450503913s infinite;
       }
       
       @-webkit-keyframes drop-38 {
       100% {
         top: 110%;
         left: 62%;
       }
       }
       
       @keyframes drop-38 {
       100% {
         top: 110%;
         left: 62%;
       }
       }
       .confetti-39 {
       width: 2px;
       height: 0.8px;
       background-color: #263672;
       top: -10%;
       left: 67%;
       opacity: 1.0042686721;
       transform: rotate(282.6749878558deg);
       -webkit-animation: drop-39 4.2718114916s 0.6194635714s infinite;
               animation: drop-39 4.2718114916s 0.6194635714s infinite;
       }
       
       @-webkit-keyframes drop-39 {
       100% {
         top: 110%;
         left: 78%;
       }
       }
       
       @keyframes drop-39 {
       100% {
         top: 110%;
         left: 78%;
       }
       }
       .confetti-40 {
       width: 6px;
       height: 2.4px;
       background-color: #d13447;
       top: -10%;
       left: 80%;
       opacity: 1.0661462617;
       transform: rotate(60.149532163deg);
       -webkit-animation: drop-40 4.8341379685s 0.497138083s infinite;
               animation: drop-40 4.8341379685s 0.497138083s infinite;
       }
       
       @-webkit-keyframes drop-40 {
       100% {
         top: 110%;
         left: 93%;
       }
       }
       
       @keyframes drop-40 {
       100% {
         top: 110%;
         left: 93%;
       }
       }
       .confetti-41 {
       width: 4px;
       height: 1.6px;
       background-color: #263672;
       top: -10%;
       left: 20%;
       opacity: 1.1423114726;
       transform: rotate(352.9712641635deg);
       -webkit-animation: drop-41 4.0289290361s 0.5732317487s infinite;
               animation: drop-41 4.0289290361s 0.5732317487s infinite;
       }
       
       @-webkit-keyframes drop-41 {
       100% {
         top: 110%;
         left: 23%;
       }
       }
       
       @keyframes drop-41 {
       100% {
         top: 110%;
         left: 23%;
       }
       }
       .confetti-42 {
       width: 3px;
       height: 1.2px;
       background-color: #263672;
       top: -10%;
       left: 51%;
       opacity: 1.0382892374;
       transform: rotate(265.0190968876deg);
       -webkit-animation: drop-42 4.8676381016s 0.1485314688s infinite;
               animation: drop-42 4.8676381016s 0.1485314688s infinite;
       }
       
       @-webkit-keyframes drop-42 {
       100% {
         top: 110%;
         left: 59%;
       }
       }
       
       @keyframes drop-42 {
       100% {
         top: 110%;
         left: 59%;
       }
       }
       .confetti-43 {
       width: 6px;
       height: 2.4px;
       background-color: #d13447;
       top: -10%;
       left: 58%;
       opacity: 1.4692339499;
       transform: rotate(147.3454829063deg);
       -webkit-animation: drop-43 4.7595597891s 0.2119457261s infinite;
               animation: drop-43 4.7595597891s 0.2119457261s infinite;
       }
       
       @-webkit-keyframes drop-43 {
       100% {
         top: 110%;
         left: 67%;
       }
       }
       
       @keyframes drop-43 {
       100% {
         top: 110%;
         left: 67%;
       }
       }
       .confetti-44 {
       width: 1px;
       height: 0.4px;
       background-color: #263672;
       top: -10%;
       left: 43%;
       opacity: 0.624980912;
       transform: rotate(214.9397918259deg);
       -webkit-animation: drop-44 4.0870134315s 0.257924056s infinite;
               animation: drop-44 4.0870134315s 0.257924056s infinite;
       }
       
   
   /*   farmer_landing */
   .farmer_landing .big_font {
       font-size: 40px;
       font-weight: 400;
       margin-bottom: 20px;
   }
   
   .farmer_landing .wp_img {
       width: 75%;
   }
   
   .farmer_landing .welcome-content {
           top: 10%;
   }
   
   .farmer_landing .middle_heading {
       font-size: 18px;
       color: #000000;
   }
     .farmer_landing .thnku-content {
       position: absolute;
       top: 10%;
       width: 100%;
       text-align: center;
   }
   
   .farmer-welcome .cashback_btn {
       margin-top: 2rem;
       text-align: center;
   }
   
   .cashback_btn a {
       width: 160px;
       display: block;
       margin: auto;
       background: #009933;
       padding: 10px 10px;
       border-radius: 10px;
       color: #ffffff !important;
       font-size: 16px;
       font-weight: 400;
   }
   
   .scan_success.show {
       height: 41% !important;
   }
   
   .scan_success .offcanvas-body {
       position: relative;
       width: 100%;
       overflow: visible;
   }
   
   .scan_success .cs_success_inner {
       position: absolute;
       top: -35px;
       width: 80%;
       left: 50%;
       transform: translate(-50%, 0px);
       text-align: center;
   }
   
   .scan_success .cs_success_inner .right_icon {
       width: 85px;
       border: 4px solid white;
       border-radius: 50%;
       margin-bottom: 20px;
   }
   
   .green_middle {
       font-size: 18px;
       color: #009933;
       font-weight: 500;
   }
   
   .f-awesome .middle_heading {
       margin-bottom: 5px;
   }
   
   .f-awesome .sweep-thnku-text ul li {
       margin-bottom: 10px;
   }
   
   .f-awesome .stars {
       width: 70px;
       margin-top: 10px;
   }
   
   .farmer_upiform .welcome-content {
           top: 40%;
           transform: translate(-50%, -50%);
       }
   
     .farmer_congratulation .cashback_img {
       position: absolute;
       bottom: 45px;
       z-index: 999;
       left: 50%;
       width: 50%;
       margin: auto;
       transform: translate(-50%, 0px);
   }
   
   .farmer_congratulation .sweep-gpay {
       margin-top: 30px;
   }
   
   .farmer_congratulation .sweep-logo {
       width: 80%;
   }
   
   .farmer_congratulation .welcome-content {
       top: 16%;
   }
   
   /*   farmer_landing */
       
   @media only screen and (max-width:1599px) {
       .welcome-content {
           top: 3%;
       }
       .sweep-thnku-text {
           margin-top: 3rem;
       }
   
   }
   
   
   @media only screen and (max-width:767px) {
       h1.sweep-welcome-heading {
           font-size: 35px;
       }
       p.sweep-welcome-para1, p.sweep-upi-para {
           font-size: 18px;
           margin-bottom: 2rem;
       }
       .sweep-thnku-text {
           margin-top: 2rem;
       }
       .sweep-input-wrapper input.form-control {
           width: 65%;
       }
       .sweep-input-wrapper select.form-select {
           width: 35%;
           margin-left: 5px;
       }
       
   
   }
   
   
   @media only screen and (max-width:376px) {
    .farmer_landing .big_font {
       font-size: 35px;
       font-weight: 400;
       margin-bottom: 15px;
   }
   .farmer_landing .wp_img {
       width: 70%;
   }
   
   .farmer_landing .welcome-content {
       top: 10%;
   }
   
   .cashback_img {
   
       bottom: 25px;
   
   }
   
   .farmer_upiform .welcome-content {
       top: 40%;
       transform: translate(-50%, -50%);
   }
   }
   
   
   @media only screen and (min-width: 1200px) and (max-width: 1399px){
   
   .farmer_landing .welcome-content {
       top: 5%;
   }
   
     .farmer_landing .sweep-thnku-text {
           margin-top: 1rem;
       }
   
   .farmer_landing .big_font {
       font-size: 30px;
       font-weight: 400;
       margin-bottom: 17px;
   }
   
   .farmer_landing .wp_img {
       width: 50%;
   }
   
   .farmer_landing .sweep-thnku-bg {
       width: 100%;
       height: 100vh;
       overflow: hidden;
       object-fit: contain;
   }
   
   main.sweep-thnku.farmer_landing {
       min-width: 320px;
       max-width: 365px;
     
   }
   
   .farmer_landing .sweep-logo {
       width: 140px;
   }
   
   .farmer_landing .sweep-welcome-heading {
       font-size: 30px;
   }
   
   .farmer_landing .middle_heading {
       font-size: 16px;
       color: #000000;
   }
   
   .farmer_congratulation .sweep-gpay {
       margin-top: 15px;
   }
   
   .farmer_congratulation p.sweep-welcome-para1, .farmer_congratulation p.sweep-upi-para {
       font-size: 18px;
       color: black;
       margin-bottom: 3rem;
   }
   
   .farmer_congratulation .cashback_img {
       bottom: 26px;
      
   }
   .farmer_upiform .welcome-content {
       top: 40%;
       transform: translate(-50%, -50%);
   }
   
    .farmer_upiform p.sweep-welcome-para1,  .farmer_upiform p.sweep-upi-para {
       font-size: 18px;
       color: black;
       margin-bottom: 2rem;
   }
   }
   
   