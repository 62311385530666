body {
    /* font-family: "lohit-punjabi"; */
    src: url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("woff"),
      url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("opentype"),
      url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("truetype");
  }
  
  
  .heading_18{
    font-size: 18px;
      color: #040c29;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 5px !important;
      display: block;
  }
  
  .description{
    color: #767c90;
      font-size: 14px;
  }
  
  svg {
    cursor: pointer;
  }
  
  .form-select {
    cursor: pointer;
  }
  
  .font-500{
    font-weight: 500;
  }
  
  .font-600{
    font-weight: 600;
  }
  
  /* new records */
  .as_zodiac_single_wrapper {
    background-color: #f2f2f2;
    color: #000000;
    /* margin-top: 73px; */
  }
  
  /* .as_zodiac_single_wrapper .astrologer_heading {
    padding-top: 95px;
  } */
  
  .as_zodiac_single_wrapper .astrologer_type .nav .nav-item a {
    padding: 15px 15px;
    color: #767c90;
    font-size: 18px;
    cursor: pointer;
  }
  
  .as_zodiac_single_wrapper .astrologer_type .nav .nav-item a.active {
    color: #886459;
    border-bottom: 2px solid #886459;
    font-weight: 500;
  }
  
  .as_zodiac_single_wrapper .border_bottom {
    border-bottom: 1px solid #b9bbbd;
    padding-bottom: 0;
  }
  
  .feed_comments_sec>ul>li p {
    font-size: 13px;
    color: #040c29;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  .feed_comments_sec>ul>li a.reply {
    font-size: 12px !important;
    color: #767c90 !important;
    display: inline-block;
  }
  
  .feed_comments_sec ul li {
    margin: 0 !important;
  }
  
  .feed_comments_sec>ul>li {
    border-bottom: 1px solid #979ba740;
    padding: 10px 0;
  }
  
  .feed_comments_sec ul.fcs_reply_sec li h4 {
    color: #040c29;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
  }
  
  .feed_comments_sec ul.fcs_reply_sec li p {
    font-size: 12px;
    color: #767c90;
    margin-bottom: 0;
    font-weight: 500;
  }
  
  .feed_comments_sec ul.fcs_reply_sec li .des.d-flex p {
    font-size: 13px;
    color: #040c29;
    font-weight: 600;
    margin-bottom: 0;
    width: 95%;
  }
  
  .feed_comments_sec ul.fcs_reply_sec li .user_info h5 {
    color: #767c90;
    font-size: 11px;
    font-style: italic;
  }
  
  .feed_comments_sec ul.fcs_reply_sec {
    width: 100%;
    background: #f0f0f0;
    padding: 10px !important;
    border-radius: 10px;
    margin-top: 10px !important;
  }
  
  .feed_comments_sec ul.fcs_reply_sec li {
    margin-right: 0 !important;
  }
  
  .feed_comments_sec i.fa.fa-ellipsis-h {
    color: #979ba7;
  }
  
  .feed_comments_sec .reply_input_sec {
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 30px;
  }
  
  .feed_comments_sec .reply_input_sec input {
    background: transparent;
    border: none;
    font-size: 12px;
    color: #040c29;
    font-weight: 600;
    width: 90%;
  }
  
  .feed_comments_sec .reply_input_sec input::placeholder {
    color: #040c29;
  }
  
  .feed_comments_sec .reply_input_sec button.btn {
    padding: 0;
    font-size: 12px;
    color: #4b3b5a;
    font-weight: 600;
  }
  
  .astro_feed_sec .feed_comments_sec ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .astro_feed_sec .feed_comments_sec ul .card-header {
    background: transparent;
    border: none;
    padding: 0;
    padding-bottom: 0;
  }
  
  .astrologer_feed_area {
    height: 90vh;
  }
  
  
  .form-control:focus {
    /* color: #ffffff; */
    background-color: transparent;
    outline: 0;
    box-shadow: none;
    border-bottom: 1px solid #b3b3b3;
  }
  
  .as_login_area .form-control {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none !important;
    color: #ffffff;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    margin-bottom: 25px;
    position: relative;
  }
  
  .form-control::placeholder {
    color: #000000;
  }
  
  button.as_btn {
    align-items: center;
    background-color: #2196f3;
    border: none!important;
    border-radius: 5px;
    color: #fff;
    display: inline-flex;
    font-size: 15px;
    font-weight: 500;
    height: 30px;
    letter-spacing: .5px;
    margin-left: 15px;
    outline: none;
    position: relative;
    text-transform: uppercase;
    transition: .2s linear;
    width: auto;
  }
  
  .header {
    border-bottom: none;
    background-color: #2196f3;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    /* background-image: linear-gradient(343deg,#b2373b,#970625); */
    padding: 8px 20px 8px 8px;
  }
  
  .navbar-light .navbar-nav .nav-link {
   color: white !important;
    font-size: 14px;
  }
  
  .header .active {
    background: #4b8ab5;
    border-radius: 4px;
  }
  
  
  
  .bar1 {
    border: 1px solid;
    width: 50%;
    /* margin: 10px;
    align-items: center;
    padding: 5px;
    margin-left: 200px; */
    float: left;
  
  }
  
  .p1 {
    border: 1px solid;
    width: 10%;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    background-color: #FBEAFF;
    border: hidden;
  }
  
  .p1:hover {
    background-color: #845EC2;
    color: white;
  }
  
  .p2 {
    border: 1px solid;
    width: 15%;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    background-color: #FBEAFF;
    border: hidden;
  }
  
  .p2:hover {
    background-color: #845EC2;
    color: white;
  }
  
  .p3 {
    border: 1px solid;
    width: 10%;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    background-color: #FBEAFF;
    border: hidden;
  }
  
  .p3:hover {
    background-color: #845EC2;
    color: white;
  }
  
  /* FacebookPost */
  
  .cardd {
    width: 30.33%;
    float: left;
    margin: 15px;
  }
  
  .card-body {
    /* width: 336px;
    height: 455px; */
    text-align: justify;
    /* margin-left: 37px; */
  
    height: 100%;
    overflow: hidden;
  }
  
  .card-img-top {
    /* width: 47%; */
    max-width: 256;
    min-width: 120px;
    height: 300px;
    /* margin-left: 100px; */
    margin-bottom: 15px;
  }
  
  .btn {
    color: #040404;
    background-color: #dcdcdc;
    border: none;
    --bs-btn-padding-y: 0.2rem !important;
    margin-top: 10px;
  }
  
  .btn:hover {
    /* color: var(--bs-btn-hover-color); */
    color: #040404 !important;
    background-color: #deeef9 !important;
    border-color: var(--bs-btn-hover-border-color);
  }
  
  .nav-link {
    margin-left: 10px;
  }
  
  .imgclass {
    width: 47%;
    max-width: 256px;
    min-width: 120px;
    height: 249px;
    margin-left: -14px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  
  
  .dailycardbody {
    width: 95%;
    height: 420px;
    text-align: justify;
    margin-left: 30px;
  }
  
  .shadow {
    margin-bottom: 29px;
    /* border-radius: 45px; */
  }
  
  .headerdd {
    font-size: 1.75rem;
    margin-bottom: 17px;
    margin-top: 18px;
  
  }
  
  .weeklycardbody {
    width: 95%;
    height: 350px;
    text-align: justify;
    margin-left: 30px;
  }
  
  .monthlycardbody {
    width: 95%;
    height: 600px;
    text-align: justify;
    margin-left: 30px;
  }
  
  .homepage {
    height: 600px;
    background-position: 100% 50%;
    background-size: cover;
  }
  
  .imgastro {
    width: 230px;
    float: left;
    /* margin-left: 23px;
    margin-top: 17px; */
    margin-left: 558px;
    margin-top: 249px;
  }
  
  .btnhome {
    color: #212529;
    background-color: #e9ecef3d;
    border-color: #2125298c;
    float: left;
    margin-top: 200px;
    margin-left: -63px;
    border-radius: 5px;
    padding: 8px;
    width: 152px;
  }
  
  #bookbtn {
    position: absolute;
    bottom: 28px;
    margin-left: 82px;
  }
  
  .festivalcardd {
    /* width: 33.33%; */
    /* float: left; */
    margin: 15px;
  }
  
  .blogbody {
    height: 620px;
  }
  
  .festivalcardbody {
    height: 500px;
  }
  
  /*login_page_css_start*/
  .form-group {
    text-align: initial;
  }
  
  .form-group {
    text-align: initial;
    margin: 10px 0;
  }
  
  span.shownpwd {
    color: #e8ba22;
    cursor: pointer;
    font-size: 20px;
    padding-right: 21px;
    z-index: 99;
    position: absolute;
    right: 0px;
    top: 10px;
  }
  
  .as_login_area {
    background-attachment: fixed;
    width: 100%;
    min-height: calc(100vh - 70px);
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(150deg, #2196f3, #fdf730);
    background-size: cover;
  }
  
  .as_login_area .login-shape {
    position: absolute;
    bottom: -90px;
    left: -90px;
  }
  
  .as_login_area .login_inner {
    width: 650px;
    margin: auto;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(255 255 255 / 20%);
    background: rgba(255,255,255,0.2);
    height: auto;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
     position: absolute;
    /* backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);  */
    align-self: flex-center;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    text-align: center;
  }
  
  .as_login_area .login_logo img {
    width: 230px;
    margin-bottom: 30px;
    object-fit: contain;
    margin-top: 30px;
  }
  
  .as_login_area .login_logo {
    text-align: center;
  }
  
  /* .as_login_area .form-control {
    height: 50px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none !important;
    color: #000000;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #221c4a;
    border-radius: 0;
  
  } */
  
  .as_login_area .login_form {
    padding: 0;
  }
  
  /*login_page_css_end*/
  /* header css start */
  .as_header_wrapper {
    display: flex;
    background-color: #07273c;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 99999;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    background-image: linear-gradient(343deg, #b2373b, #970625);
    top: 0;
  }
  
  .as_header_wrapper .as_logo img {
    object-fit: contain;
  }
  
  .as_logo {
    padding: 22px 50px;
    border-right: 1px solid hsla(0, 0%, 100%, .1);
    display: flex;
    align-items: center;
    background: #fff;
  }
  
  .as_menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .as_menu ul li.login_btn a {
    background: #e8b922;
    padding: 5px 20px !important;
    color: #ffffff !important;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    overflow: hidden;
    position: relative;
  }
  
  .as_menu ul li.login_btn a span {
    position: relative;
    z-index: 1;
  }
  
  .main-navbar .dropdown-menu {
    left: -80px !important;
    margin-top: 10px;
  }
  
  
  
  
  .chat-users-list .chat-user {
    cursor: pointer;
  }
  
  .chat-form input {
    width: 90% !important;
    padding: 10px 15px !important;
  }
  
  .chat-form button {
    padding: 10px 0;
    width: 105px;
    font-size: 16px;
  }
  
  
  .chat-users-list .chat-user.active {
    color: #fff;
    background-color: #2196f3;
  }
  .chat-users-list .chat-user .text-time {
    color: black;
  }
  
  .chat-users-list .chat-user:hover .text-time {
    color: white;
  }
  
  .chat-users-list .chat-user:hover {
    background-color:#2196f3;
  }
  
  .chat-users-list .chat-user:hover .user_name h4, .chat-users-list .chat-user:hover .user_name p {
    color: #ffffff;
  }
  
  
  .chat-window {
    border: 1px solid;
  }
  
  .chat-header {
    border-bottom: 1px solid;
    padding: 10px 10px;
    background-color: #07273c;
    color: #fff;
  }
  
  .chat-header span {
    cursor: pointer;
  }
  
  .chat-history {
    padding: 10px 10px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 485px;
  }
  
  .event-pointer {
    cursor: pointer;
  }
  
  .btnHover:hover {
    color: white !important;
    background-color: #25658f;
  }
  
  .pointerEvent {
    cursor: pointer;
  }
  
  #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  
  .textAreaHeight {
    height: 200px !important;
  }
  
  .modalInputs {
    outline: none;
    border-radius: 7px;
  }
  
  .modalInputs:focus {
    border: 2px solid #25658f;
  }
  
  .adding-pre-wrap{
    white-space: pre-wrap !important;
  }
  
  .calLoader {
    position: absolute !important;
    display: flex;
    background-color: white;
    justify-content: center;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  
  #justify-tab-example-tab-content,
  #justify-tab-example-tab-wishes {
    font-weight: 700;
    color: #25658f;
  }
  
  .tabs-color-class {
    font-weight: 500;
    color: #25658f;
  }
  
  .tabs-color-class:hover {
    color: #585858;
  }
  
  .thumbnail-custom-image {
    color: #25658f;
    padding: 3px 5px;
    border-radius: 6px;
    border: 2px solid #25658f;
  }
  
  .pointerUtilityClass {
    cursor: pointer;
  }
  
  .z_Index {
    z-index: 1000 !important;
  }
  
  #inputds {
    display: none;
  }
  
  
  .required:after {
    content: " *";
    color: red;
  }
  
  .modal-sm {
    --bs-modal-width: 400px;
  }
  
  .model {
    --bs-modal-margin: 4.75rem !important;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .spinner{
    position: relative;
    top: 50%;
    left: 50%;
  }
  .spinneruser{
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  /* .form-select {
    padding: 0.925rem 2.25rem 0.375rem 0.75rem !important;
  } */
  
  ._active {
    background-color: green;
    color: white;
    border: 2px solid green;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  
  }
  
  ._active:hover {
    border: 2px solid rgb(3, 83, 3);
  }
  
  ._inactive:hover {
    border: 2px solid brown;
  }
  
  ._inactive {
    background-color: red;
    color: white;
    border: 2px solid red;
    padding: 2px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  
  
  }
  
  
  
  
  .Dt-btn {
    background-color: red;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    border: 2px solid red;
    padding: 2px 4px 2px;
    font-weight: 500;
  }
  
  .Et-btn:hover {
    border: 2px solid orangered;
  }
  
  .Dt-btn:hover {
    border: 2px solid brown;
  }
  
  
  .Et-btn {
    background-color: orange;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    border: 2px solid orange;
    padding: 2px 12px;
    font-weight: 500;
  }
  
  
  .sidenav {
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    /* top: 0; */
    left: 0;
    background-color: white;
    overflow-x: hidden;
    padding-top: 20px;
    margin-top: 30px;
  }
  
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    margin-top: 4px;
  }
  
  .sidenav a:hover {
    color: lightblue;
  }
  
  .activel {
    background-color: #25658f;
    color: #fff !important;
    border-radius: 4px;
    margin-left: 8px;
  }
  
  .main {
    margin-left: 160px;
    /* Same as the width of the sidenav */
    font-size: 28px;
    /* Increased text to enable scrolling */
    padding: 0px 10px;
  }
  
  /* 
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  } */
  .sidenav {
    padding-top: 10px;
  }
  
  .sidenav a {
    font-size: 16px;
  }
  
  #labimg {
    width: 30%;
    display: block !important;
  }
  
  #labimg input {
    display: none;
  }
  
  .hd-cr{
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    padding: 40px;
  }
  
  .grid-container2 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    padding: 40px;
  }
  
  .grid-container3 {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    padding: 40px;
  }
  
  .grid-container>div {
    background-color: white;
    border: 1px solid rgb(202, 198, 198);
    border-radius: 8px;
    text-align: center;
  
  
  }
  
  .grid-container2>div {
    background-color: white;
    border: 1px solid rgb(202, 198, 198);
    border-radius: 8px;
    text-align: center;
  }
  
  .calicon {
    margin-right: 4px;
  }
  
  .newdiv {
  margin: 40px;
  }
  
  #imgone {
    margin: 30px;
  }
  
  .card-body-b {
    padding: 40px !important;
    width: auto !important;
    margin-left: 0 !important;
  }
  
  .newdiv1 {
    margin: 60px 240px;
  }
  
  
  .card-img-top-b {
    width: 100%;
    height: 130px !important;
  }
  
  
  .card-img-top-i {
    width: 30% !important;
  
  }
  
  .modal {
    --bs-modal-width: 800px !important;
    --bs-modal-margin: 8.75rem !important;
  }
  
  
  .modal-header {
    color: white !important;
    background-image: linear-gradient(343deg,#b2373b,#970625);
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }
  
  .modal-header-hd {
    background-color: white !important;
    color: #000000 !important;
  }
  
  .success_btn {
    background-color: red !important;
    border-color: red;
    color: white;
  }
  
  .cancle_btn {
    background-color: lightslategray !important;
    border-color: lightslategray;
    color: white;
  }
  
  .add-btn {
    background-color: lightblue;
  }
  .btn.show {
    color: #000000 !important;
  }
  .dropselect .btn{
    background-color: white !important;
  }
  
  .mrup {
    margin: 10px 0 0 0;
  }
  
  .card {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  .card-title-p {
    text-align: center;
    font-size: medium;
  }
  
  .card-title {
    text-align: center;
  }
  
  .card-text {
    text-align: center;
    font-size: 13px;
  }
  
  .textst {
    color: #4d4c4c;
    word-spacing: 5px;
    font-size: medium;
  
  }
  
  .btn-more {
    border: none;
    background-color: white;
    color: blue;
    font-size: large;
  }
  
  .btn-more:hover {
    background-color: white !important;
    color: #f39d00 !important;
  
  }
  
  .cal-dat {
    text-align: center;
    font-size: 15px;
  }
  
  .by-btn {
    margin-right: 24px;
  }
  .by-btn-sec {
    margin-right: 24px;
    margin-left: 79%;
  }
  .left-q {
    margin-left: 4px;
  }
  
  .tab-hd-row {
    display: grid;
    grid-template-columns: auto 10% 8%;
    margin-top: 10px;
  
  }
  
  .containerM {
    position: relative;
  }
  
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: rgb(144, 120, 120);
    width: 90%;
    text-align: center;
  }
  
  .topVIicon {
    position: absolute;
    top: 0;
    margin-left: 10px;
    font-size: 20px;
  }
  
  .subtitle-b {
    font-weight: bold;
  }
  
  .show_btn {
    background-color: lightblue;
    color: #040c29;
    text-align: right;
    font-size: medium;
    padding: 6px 15px;
    cursor: pointer;
  }
  
  .gallery {
    display: flex;
  }
  
  .gallery img {
    margin: 10px;
  }
  
  .tempFile {
    width: 40%;
    height: 40%;
    margin: 40px 50px;
    border-radius: 8px;
  }
  
  .managaldosh-form-style{
    width: 400px !important;
  }
  
  .managaldosh-form-style button{
    background-color: #25658f;
    color: white;
  }
  
  .managaldosh-form-style input{
    height: 35px;
  }
  
  .managaldosh-form-style label {
    font-size: 14px !important;
    color: #7e7d7d;
    margin-bottom: 5px !important;
  }
  .dt-btn{
    color: red;
  }
  
  .hd-list {
    margin: 15px;
    color: #25658f;
  }
  
  .hd-list-f {
    color: #25658f;
    margin-top: 10px;
  
  }
  
  .Wc {
    width: 40%;
    padding: 50px;
    border: 1px solid rgb(193, 191, 191);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    margin-top: 25px;
  }
  
  .tableWc {
  
    padding: 50px;
    border: 1px solid rgb(193, 191, 191);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    margin-top: 25px;
  }
  
  .imglist {
    width: 15%;
  }
  
  /* ========================= */
  
  .pagination>li {
    list-style: none;
    /* border: 0.3px solid; */
  }
  
  .pagination>li>a,
  .pagination>li>span {
    float: left;
    padding: 8px 20px;
    line-height: 1.5;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .pagination>li.active>a {
    color: #fff !important;
    background-color: #9d102a;
    border-color: #9d102a;
  }
  .pagination>li>a:hover {
    /* background-color:#25658f;
    color: white; */
    cursor: pointer;
  }
  
  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    margin-left: 0;
  }
  
  .pagination {
    float: right !important;
    margin-right: 20px;
   --bs-pagination-color: black !important;
   --bs-pagination-active-bg:#25658f !important;
  }
  
  
  .chat-search-field{
      position: relative;
  }
  
  .chat-bot-search-icon {
    position: absolute;
    left: 14px;
    top: 7px;
  }
  
  .chat-search-field .form-input {
    height: 35px;
    width: 100%;
    text-indent: 33px;
    border-radius: 30px;
  }
  
  #dropdown-for-userby:hover{
  color:black !important;
  background-color:transparent !important;
  }
  
  .radio-btn-dosh-form{
    padding: 1px 2px;
    border-radius: 5px;
    border: 1px solid #25658f;
    background-color: transparent;
    border-radius: 8px !important;
  }
  
  .dosh-btns input[type="radio"]:checked + label {
    background-color: #25658f !important;
    color: white !important;
  }
  
  .dosh-btns input[type="radio"]:hover + label {
    background-color: #25658f !important;
    color: white !important;
  }
  
  .dosh-response-area{
    height: 100px;
    background-color: #07273c;
    padding: 5px;
    overflow-y: scroll;
  }
  
  .chat-context-menu{
    background-color:white;
    color: black;
    width: 180px;
    max-height: 240px;
    overflow-y: scroll;
    padding: 3px 10px;
    border:2px solid black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    z-index: 99;
    flex-direction: column;
  }
  
  .chat-context-menu > button{
    color: black;
    background: transparent !important;
    border: none;
    text-align: left;
  }
  
  .chat-context-menu > button:hover{
    border-radius: 10px;
    background: black !important;
    color: white;
  }
  
  
  .label-selected-menu {
    box-shadow: none!important;
    font-size: 14px!important;
    font-weight: 500!important;
    height: 30px!important;
    padding: 0 9px!important;
    width: 100%!important;
  }
  .label-filter-menu{
    background-image: none !important;
    width: 100px !important;
    height: 40px !important;
    padding: 0  10px!important;
    box-shadow: none !important;
  }
  
  
  .label-items-header{
    color: white;
    background-color: #25658f;
    text-align: center;
    font-weight: 700;
    border-radius: 15px 50px;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid {
    border-color:rgb(141, 137, 137) !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none !important;
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color:black !important;
  }
  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color:rgb(141, 137, 137) !important ;
  }
  .was-validated .form-select:valid, .form-select.is-valid {
    border-color:rgb(141, 137, 137) !important ;
  }
  
  ::placeholder
  {
  padding-top:8px;
  }
  .custom-pagination .pagination {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    float: inherit !important;
  }
  .chat-users-list .custom-pagination {
      padding: 7px 0!important;
      width: 100% !important;
      position: absolute !important;
      bottom: 0;
      background-color: white;
  }
  
  .custom-pagination .pagination>li>a {
    padding: 2px 6px !important;
    float: none !important;
    font-size: 12px;
    font-weight: 700;
    margin-right: 3px !important;
    border-color: #808080;
    color: #808080;
  }
  
  .custom-pagination .pagination .previous a {
    padding: 2px 3px !important;
    color: #9c1029;
    margin: 0 3px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    border-color: #9c0f29 !important;
  }
  .custom-pagination .pagination .next a {
    padding: 2px 3px !important;
    color: #9c1029;
    margin: 0 0px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    border-color: #9c0f29 !important;
    margin-right: 0 !important;
  }
  
  
  
  /* chat_windows */
  
  .chat_window {
      padding: 0;
  }
  
  .chat_window > .row {
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
  }
  .chat-users-list {
    border-right: 1px solid;
    min-height: calc(100vh - 55px);
    padding: 0;
    position: relative;
  }
  
  .chat-users-list .user_list_header{
    padding: 10px 10px;
    background: #ededed;
  }
  
  .chat_search_box {
    background: #f7f7f7;
    padding: 5px 10px;
  }
  
  .chat_all_users {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    position: relative;
  }
  .chat-users-list .chat-user {
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid #ededed;
  }
  
  .chat-users-list .chat-user .user_name h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px !important;
  }
  
  .chat-users-list .chat-user  .user_name p {
    font-size: 14px;
    color: #7f7575;
    font-weight: normal;
    margin: 0;
  }
  
  .chat-users-list .chat-user .user_info svg {
    margin-top: 5px;
  }
  
  .Time {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .Time span {
    color: #818181;
    font-size: 10px;
  }
  
  
  .chat-users-list .chat-user.active .user_name h4, .chat-users-list .chat-user.active  .user_name p{
    color:#ffffff
  }
  .chat-users-list .chat-user.active .Time span {
    color: #ffffff;
    font-size: 12px;
  }
  
  .main-logo {
    color: #fdf730;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1px 0;
  }
  
  .chat-section .chat-window {
    border: none;
    min-height: calc(100vh - 157px);
  }
  
  .chat-section .chat-window .chat-header {
    border-bottom: none;
    padding: 10px 10px;
    background-color: #fdf730;
    color: #000;
    font-weight: 600;
  }
  
  .chat-section .chat-window .bot-right {
    background: #dcf8c7 !important;
    color: #000;
    border-radius: 20px !important;
    border-bottom-right-radius: 0 !important;
    text-align: left !important;
    width: 45% !important;
    padding: 20px !important;
  }
  
  .chat-section .chat-window .user-left {
    padding: 15px !important;
    border-radius: 20px !important;
    background: #ffffff !important;
    border-bottom-left-radius: 0 !important;
  }
  
  .chat-history {
    padding: 10px 10px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 157px);
  }
  
  button#dropdown-for-userby {
    font-weight: 600;
    position: relative;
    top: -2px;
  }
  form.chat-form.d-flex.d-block {
    background: #ededed;
    display: flex !important;
    align-items: center;
    height: 58px;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  form.chat-form textarea {
    width: 90%;
    border: 1px solid #dee2e6 !important;
    height: 40px;
    color: #000000;
    padding: 0px 15px !important;
    line-height: normal;
  }
  
  form.chat-form textarea::placeholder {
    opacity: 0.5;
    vertical-align: middle;
  }
  
  form.chat-form textarea:focus::placeholder {
    opacity: 0;
  }
  
  
  form.chat-form textarea:focus-visible{
    padding: 8px 15px !important;
    line-height: 15px;
    border: none !important;
  }
  
  
  
  
  
  form.chat-form button.border.as_btn.send {
    text-align: center !important;
    color: #ffffff !important;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 6px;
    padding-right: 15px;
  }
  form.chat-form .download_icon svg {
    font-size: 32px;
    padding-right: 5px;
    padding-top: 7px;
    margin-bottom: 0;
    color: #2196f3;
    font-weight: 800 !important;
  }
  
  label.radio-btn-dosh-form {
    background: #2196f3;
    color: #ffffff;
    border: none;
    border-radius: 5px !important;
    padding: 6px 4px !important;
  }
  
  .chat-search-field input.form-control.form-input{
    color:#000;
  }
  
  .chat-search-field input.form-control.form-input::placeholder {
    color: black;
  }
  
  .user_list_header .fw-bold.me-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  span.user_issue {
    background: red;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-style: normal;
    margin-left: 7px;
    padding: 2px 5px;
  }
  
  /* header */
  
  .header a.navbar-brand {
    background: #ffffff;
    height: 70px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: white !important;
    padding: 8px 15px;
    position: relative;
    font-size: 15px;
    transition: all .3s linear;
    margin-left: 0;
  }
  
  .navbar-light .navbar-nav .nav-link:hover {
    color: #fdf730 !important;
    background: transparent;
  
  }
  
  .navbar-light .navbar-nav .nav-link.active {
    color: #fdf730 !important;
    background: transparent !important;
  
  }
  .navbar-light .navbar-nav .nav-link:before{
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color:#fdf730;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
  }
  
  .navbar-light .navbar-nav .nav-link.active:before, .navbar-light .navbar-nav .nav-link:hover:before {
    width: 35px;
  }
  
  /* feed_area */
  .feed_area {
    position: relative;
    width: 100%;
    padding-top: 50px;
  }
  
  .feed_box.card {
    overflow: hidden;
    margin-bottom: 30px;
  }
  .feed_box.card .card-header {
    position: relative;
    padding: 0;
    width: 100%;
  }
  
  .feed_box.card .card-header .user_img {
    width: 100%;
    object-fit: cover;
    height: 270px;
    object-position: center;
  }
  
  .feed_box.card .card-header  .trash_top {
    position: absolute;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
    padding: 0;
  }
  
  .feed_box.card .card-header .trash_top .serial_no {
    background: #e8ba22;
    color: #fff;
    margin-top: 8px;
    padding: 0 7px;
    border-radius: 0 15px 15px 0px;
    font-size: 14px;
    font-weight: 600;
    padding-right: 13px;
    line-height: 26px;
    left: 0;
  }
  
  .feed_box.card .card-header .trash_top  span.icon {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 9px;
    margin-right: 7px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    margin-top: 10px;
  }
  
  .feed_box.card  .card-body .info h6 {
    font-size: 18px;
    color: #040c29;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px !important;
    display: block;
  }
  
  .feed_box.card  .card-body  .info>p {
    color: #767c90;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .feed_box.card .card-body .text_description>p {
    color: #767c90;
    font-size: 14px;
  
  }
  
  .feed_box.card  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .feed_box.card  .text_description {
    height: 200px;
    overflow-y: scroll;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  
  .feed_box.card .card-footer .cursor-pointer{
    display: flex;
    font-size: 12px;
    align-items: center;
    font-weight: 600;
  }
  
  .feed_box.card .card-footer .cursor-pointer button{
    display: flex;
    font-size: 12px;
    align-items: center;
    font-weight: 600;
  }
  
  .feed_box.card .card-footer .cursor-pointer span {
    font-size: 12px;
    font-weight: 600;
  }
  
  .feed_box.card .card-footer .cursor-pointer svg {
    margin-right: 5px;
  }
  
  /* gita_qoute */
  .gita_quote_heading {
    background: #fff;
    height: clamp(73px,73px,73px);
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  
    display: flex;
  }
  
  .gita_quote_heading button.as_btn {
    height: 45px;
    background-color: #2196f3;
    font-size: 16px;
    padding: 0px 14px;
    position: relative;
    margin-left: 0;
   
  }
  
  .gita_quote_heading .hd-list-f {
    color: #4b3b5a;
    margin: 0;
  }
  
  .admin_grid_table .table > thead tr th {
    background: #a21a2e;
    color: #ffffff;
    font-size: 14px;
    text-align: left !important;
    padding: 12px 15px;
  }
  
  .admin_grid_table table.table {
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 10px !important;
    background: #ffffff;
  }
  .admin_grid_table table.table tbody tr td {
    vertical-align: middle;
    color: #767c90 !important;
    font-size: 14px;
    padding: 5px 15px;
  }
  
  .admin_grid_table table.table tbody tr td:nth-child(2) {
    width: 68%;
    margin-right: 30px !important;
  }
  
  
  .admin_grid_table table.table tbody tr td:nth-child(6) {
    width:150px;
  
  }
  
  .page-link.active, .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #a21a2e;
    border-color: #a21a2e;
  }
  
  /* aarti section */
  
  .main.aarti_section {
    margin-left: 0;
    font-size: 28px;
    padding: 0;
  }
  
  .main.aarti_section .sidenav {
    height: calc(100vh - 200px);
    width: 100%;
    position: sticky;
    top: 9px;
    z-index: 1;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 0px;
    margin-top: 0px;
    padding: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  
  .main.aarti_section .sidenav a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    margin: 0 !important;
    border-top: 1px solid #ededed;
  }
  .main.aarti_section .sidenav a.activel {
    background-color: #9d102a;
    color: #fff !important;
    border-radius: 4px;
    margin-left: 8px;
  }
  
  
  .aarti_inner .card .card-header {
    position: relative;
    width: 100%;
    border: none;
    height: 250px;
  }
  
  .aarti_inner .card .card-header img {
    object-fit: cover;
    height: 235px;
  }
  
  .aarti_inner .card .card-header  .icon {
    position: absolute;
    top: 0;
    right: 24px;
    background: #ffffff;
    border-radius: 30px;
    padding: 0 15px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
  }
  
  
  
  .height_box {
    min-height: 220px;
  }
  
  .download_icon {
    position: relative;
  }
  
  .download_icon  span.cross svg {
    font-size: 14px !important;
    padding: 0 !important;
  }
  .download_icon span.cross {
    position: absolute;
    right: -8px;
    top: -6px;
  }
  .img-fluid-log {
    max-width: 50% !important;
  }
  
  .Multiple_button {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .Multiple_button button.as_btn {
    align-items: center;
    background-color: #2196f3;
    border: none!important;
    border-radius: 5px;
    color: #fff;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    height: 26px;
    margin-left: 15px;
    outline: none;
    position: relative;
    text-transform: capitalize;
    transition: .2s linear;
    width: auto;
  }
  
  @media only screen and (max-width: 1399px) {
    .label-selected-menu {
      box-shadow: none!important;
      font-size: 13px!important;
      font-weight: 500!important;
      height: 30px!important;
      padding: 0 10px!important;
      width: 100%!important;
  }
  }
  
  @media only screen and (max-width: 767px) {
  .as_login_area .login_inner {
    width: 95%;
   
  }
  
  .as_login_area .img-fluid-log {
    max-width: 100%;
    width: 200px;
  }
  
  .chat_window {
    position: relative;
  }
  
  .chat_window .chat-section.col-xl-9.col-lg-8.col-md-8 {
    position: absolute;
    left: 0;
    width: 100%;
  }
  
  .chat-users-list {
    border-right: none;
   
  }
  
  .managaldosh-form-style {
    width: 100% !important;
  }
  
  .main-logo {
    font-size: 17px;
  }
  
  
  
  }
  @media only screen and (max-width: 440px) {
  
    .chat-section .chat-window .chat-header {
    border-bottom: none;
    padding: 10px 10px;
    background-color: #ededed;
    color: #000;
    font-weight: 600;
    display: flex;
    align-items: start;
    flex-direction: column-reverse;
    position: relative;
  }
  
  .chat-section .chat-window .chat-header span.float-end.d-flex.me-3 {
    float: initial !important;
    margin-top: 8px;
  }
  
  .chat-section .chat-window .chat-header span.float-end.me-2 {
    position: absolute;
    right: 5px;
    top: 11px;
  }
  
  }

  html {
  
   height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  }
  
  
  body {
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    
  }
  
  h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
  }
  ul, ol{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a{
    color: inherit;
    text-decoration: none;
  }
  a:hover, a:focus{
    color: inherit;
    text-decoration: none;
  }
  .btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
  }
  button:focus {
    outline: 0;
    box-shadow: none;
  }
  
  .welcome-content{
      position: absolute;
      top:7%;
      width: 90%;
      text-align: center;
      margin: auto;
      left: 50%;
      transform: translateX(-50%);
  }
  
  
  h1.sweep-welcome-heading {
      font-size: 40px;
      text-transform: uppercase;
      color: #009933;
      text-align: center;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 15px;
  }
  h1.sweep-welcome-heading::after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 50%;
      height: 2px;
      width: 150px;
      background-color: white;
      transform: translateX(-50%);
      border-radius: 50%;
  }
  
  p.sweep-welcome-para1, p.sweep-upi-para {
      font-size: 25px;
      color: black;
      margin-bottom: 3rem;
  }
  .farmer_congratulation p.sweep-upi-para{
    margin-bottom: 1rem;
  }
  .sweep-upi-subtext {
    font-size: 20px;
    color: #000000;
}

  .sweep-gpay {
      width: 100%;
  }
  
  .sweep-input-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  }
  .sweep-input-wrapper input.form-control {
      width: 70%;
  }
  
  .sweep-input-wrapper select.form-select {
      width: 30%;
      margin-left: 5px;
  }
  
  button.btn.sweep-submit{
      font-size: 20px;
      margin: 1rem 0;
      padding: 8px 20px;
  }
  
  
  
  
  /* Thank you page */
  
  main.sweep-thnku {
      background: #ffffff;
      min-width: 320px;
      max-width: 480px;
      width: 100%;
      display: block;
      margin: auto;
      border: 0 none;
      height: 100%;
      position: relative;
      overflow: hidden;
  }
  section.thnk-page {
      min-height: 100%;
      position: relative;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top !important;
      text-align: center;
      height: auto;
  }
  .sweep-thnku-bg {
      width: 100%;
      height: 100vh !important;
      overflow: hidden;
      object-fit: cover;
  }
  
  .thnku-content {
      position: absolute;
      top: 15%;
      width: 100%;
      text-align: center;
  }
   img.sweep-logo {
      width: 170px;
  }
  .sweep-thnku-text {
      margin-top: 4rem;
  }
  
  .confetti {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #ffcc00;
      opacity: 0;
      animation: confettiAnimation 3s infinite;
      top: 0;
    }
  
  
  
  
    @keyframes confettiAnimation {
      0% {
        opacity: 0;
        transform: translateY(0);
      }
      100% {
        opacity: 1;
        transform: translateY(100vh);
      }
    }
  
  
  
  
  
    [class|=confetti] {
      position: absolute;
      z-index: 99;
      }
      
      .confetti-0 {
      width: 5px;
      height: 2px;
      background-color: #ffbf00;
      top: -10%;
      left: 33%;
      opacity: 1.3202434448;
      transform: rotate(40.2463283311deg);
      -webkit-animation: drop-0 4.00788746s 0.6050063989s infinite;
              animation: drop-0 4.00788746s 0.6050063989s infinite;
      }
      
      @-webkit-keyframes drop-0 {
      100% {
        top: 110%;
        left: 38%;
      }
      }
      
      @keyframes drop-0 {
      100% {
        top: 110%;
        left: 38%;
      }
      }
      .confetti-1 {
      width: 2px;
      height: 0.8px;
      background-color: #ffbf00;
      top: -10%;
      left: 6%;
      opacity: 1.0197218672;
      transform: rotate(71.920755702deg);
      -webkit-animation: drop-1 4.7022674111s 0.1261345896s infinite;
              animation: drop-1 4.7022674111s 0.1261345896s infinite;
      }
      
      @-webkit-keyframes drop-1 {
      100% {
        top: 110%;
        left: 20%;
      }
      }
      
      @keyframes drop-1 {
      100% {
        top: 110%;
        left: 20%;
      }
      }
      .confetti-2 {
      width: 3px;
      height: 1.2px;
      background-color: #263672;
      top: -10%;
      left: 29%;
      opacity: 0.8118035305;
      transform: rotate(72.7259131773deg);
      -webkit-animation: drop-2 4.6005602953s 0.9783159869s infinite;
              animation: drop-2 4.6005602953s 0.9783159869s infinite;
      }
      
      @-webkit-keyframes drop-2 {
      100% {
        top: 110%;
        left: 39%;
      }
      }
      
      @keyframes drop-2 {
      100% {
        top: 110%;
        left: 39%;
      }
      }
      .confetti-3 {
      width: 7px;
      height: 2.8px;
      background-color: #d13447;
      top: -10%;
      left: 15%;
      opacity: 0.9967266418;
      transform: rotate(102.1816428606deg);
      -webkit-animation: drop-3 4.0797847677s 0.6803048788s infinite;
              animation: drop-3 4.0797847677s 0.6803048788s infinite;
      }
      
      @-webkit-keyframes drop-3 {
      100% {
        top: 110%;
        left: 30%;
      }
      }
      
      @keyframes drop-3 {
      100% {
        top: 110%;
        left: 30%;
      }
      }
      .confetti-4 {
      width: 4px;
      height: 1.6px;
      background-color: #ffbf00;
      top: -10%;
      left: 35%;
      opacity: 0.9212695523;
      transform: rotate(234.2745316634deg);
      -webkit-animation: drop-4 4.0809231819s 0.7421063258s infinite;
              animation: drop-4 4.0809231819s 0.7421063258s infinite;
      }
      
      @-webkit-keyframes drop-4 {
      100% {
        top: 110%;
        left: 43%;
      }
      }
      
      @keyframes drop-4 {
      100% {
        top: 110%;
        left: 43%;
      }
      }
      .confetti-5 {
      width: 5px;
      height: 2px;
      background-color: #263672;
      top: -10%;
      left: 15%;
      opacity: 1.1531113414;
      transform: rotate(161.6705227453deg);
      -webkit-animation: drop-5 4.7838282722s 0.1796326151s infinite;
              animation: drop-5 4.7838282722s 0.1796326151s infinite;
      }
      
      @-webkit-keyframes drop-5 {
      100% {
        top: 110%;
        left: 27%;
      }
      }
      
      @keyframes drop-5 {
      100% {
        top: 110%;
        left: 27%;
      }
      }
      .confetti-6 {
      width: 6px;
      height: 2.4px;
      background-color: #263672;
      top: -10%;
      left: 77%;
      opacity: 1.3771139412;
      transform: rotate(36.6276476039deg);
      -webkit-animation: drop-6 4.5441813999s 0.2856592948s infinite;
              animation: drop-6 4.5441813999s 0.2856592948s infinite;
      }
      
      @-webkit-keyframes drop-6 {
      100% {
        top: 110%;
        left: 83%;
      }
      }
      
      @keyframes drop-6 {
      100% {
        top: 110%;
        left: 83%;
      }
      }
      .confetti-7 {
      width: 7px;
      height: 2.8px;
      background-color: #ffbf00;
      top: -10%;
      left: 50%;
      opacity: 1.1944068423;
      transform: rotate(125.0653776223deg);
      -webkit-animation: drop-7 4.9578502309s 0.0763053588s infinite;
              animation: drop-7 4.9578502309s 0.0763053588s infinite;
      }
      
      @-webkit-keyframes drop-7 {
      100% {
        top: 110%;
        left: 60%;
      }
      }
      
      @keyframes drop-7 {
      100% {
        top: 110%;
        left: 60%;
      }
      }
      .confetti-8 {
      width: 6px;
      height: 2.4px;
      background-color: #ffbf00;
      top: -10%;
      left: 94%;
      opacity: 1.1188684956;
      transform: rotate(64.1711498041deg);
      -webkit-animation: drop-8 4.179453089s 0.2311027327s infinite;
              animation: drop-8 4.179453089s 0.2311027327s infinite;
      }
      
      @-webkit-keyframes drop-8 {
      100% {
        top: 110%;
        left: 98%;
      }
      }
      
      @keyframes drop-8 {
      100% {
        top: 110%;
        left: 98%;
      }
      }
      .confetti-9 {
      width: 4px;
      height: 1.6px;
      background-color: #ffbf00;
      top: -10%;
      left: 3%;
      opacity: 1.2029052961;
      transform: rotate(90.78871679deg);
      -webkit-animation: drop-9 4.2744658837s 0.6461935805s infinite;
              animation: drop-9 4.2744658837s 0.6461935805s infinite;
      }
      
      @-webkit-keyframes drop-9 {
      100% {
        top: 110%;
        left: 17%;
      }
      }
      
      @keyframes drop-9 {
      100% {
        top: 110%;
        left: 17%;
      }
      }
      .confetti-10 {
      width: 4px;
      height: 1.6px;
      background-color: #ffbf00;
      top: -10%;
      left: 25%;
      opacity: 0.9172690217;
      transform: rotate(305.5832177105deg);
      -webkit-animation: drop-10 4.8984568518s 0.5485274434s infinite;
              animation: drop-10 4.8984568518s 0.5485274434s infinite;
      }
      
      @-webkit-keyframes drop-10 {
      100% {
        top: 110%;
        left: 32%;
      }
      }
      
      @keyframes drop-10 {
      100% {
        top: 110%;
        left: 32%;
      }
      }
      .confetti-11 {
      width: 5px;
      height: 2px;
      background-color: #ffbf00;
      top: -10%;
      left: 10%;
      opacity: 0.7560060122;
      transform: rotate(13.9522179118deg);
      -webkit-animation: drop-11 4.6203586863s 0.1949483933s infinite;
              animation: drop-11 4.6203586863s 0.1949483933s infinite;
      }
      
      @-webkit-keyframes drop-11 {
      100% {
        top: 110%;
        left: 21%;
      }
      }
      
      @keyframes drop-11 {
      100% {
        top: 110%;
        left: 21%;
      }
      }
      .confetti-12 {
      width: 2px;
      height: 0.8px;
      background-color: #ffbf00;
      top: -10%;
      left: 49%;
      opacity: 0.6075255585;
      transform: rotate(9.7196128937deg);
      -webkit-animation: drop-12 4.7287966795s 0.8300035484s infinite;
              animation: drop-12 4.7287966795s 0.8300035484s infinite;
      }
      
      @-webkit-keyframes drop-12 {
      100% {
        top: 110%;
        left: 64%;
      }
      }
      
      @keyframes drop-12 {
      100% {
        top: 110%;
        left: 64%;
      }
      }
      .confetti-13 {
      width: 8px;
      height: 3.2px;
      background-color: #ffbf00;
      top: -10%;
      left: 31%;
      opacity: 1.3557586589;
      transform: rotate(255.5462923458deg);
      -webkit-animation: drop-13 4.6707591736s 0.7662715736s infinite;
              animation: drop-13 4.6707591736s 0.7662715736s infinite;
      }
      
      @-webkit-keyframes drop-13 {
      100% {
        top: 110%;
        left: 36%;
      }
      }
      
      @keyframes drop-13 {
      100% {
        top: 110%;
        left: 36%;
      }
      }
      .confetti-14 {
      width: 4px;
      height: 1.6px;
      background-color: #263672;
      top: -10%;
      left: 76%;
      opacity: 0.870295072;
      transform: rotate(46.8429821265deg);
      -webkit-animation: drop-14 4.9550860575s 0.5566500851s infinite;
              animation: drop-14 4.9550860575s 0.5566500851s infinite;
      }
      
      @-webkit-keyframes drop-14 {
      100% {
        top: 110%;
        left: 81%;
      }
      }
      
      @keyframes drop-14 {
      100% {
        top: 110%;
        left: 81%;
      }
      }
      .confetti-15 {
      width: 6px;
      height: 2.4px;
      background-color: #263672;
      top: -10%;
      left: 52%;
      opacity: 0.9130583058;
      transform: rotate(62.0205084772deg);
      -webkit-animation: drop-15 4.9338261684s 0.9434993503s infinite;
              animation: drop-15 4.9338261684s 0.9434993503s infinite;
      }
      
      @-webkit-keyframes drop-15 {
      100% {
        top: 110%;
        left: 63%;
      }
      }
      
      @keyframes drop-15 {
      100% {
        top: 110%;
        left: 63%;
      }
      }
      .confetti-16 {
      width: 7px;
      height: 2.8px;
      background-color: #ffbf00;
      top: -10%;
      left: 53%;
      opacity: 1.3852816829;
      transform: rotate(196.7895514588deg);
      -webkit-animation: drop-16 4.436141375s 0.8744228164s infinite;
              animation: drop-16 4.436141375s 0.8744228164s infinite;
      }
      
      @-webkit-keyframes drop-16 {
      100% {
        top: 110%;
        left: 57%;
      }
      }
      
      @keyframes drop-16 {
      100% {
        top: 110%;
        left: 57%;
      }
      }
      .confetti-17 {
      width: 2px;
      height: 0.8px;
      background-color: #263672;
      top: -10%;
      left: 23%;
      opacity: 1.2110136065;
      transform: rotate(189.0302890737deg);
      -webkit-animation: drop-17 4.3242873416s 0.0882276017s infinite;
              animation: drop-17 4.3242873416s 0.0882276017s infinite;
      }
      
      @-webkit-keyframes drop-17 {
      100% {
        top: 110%;
        left: 33%;
      }
      }
      
      @keyframes drop-17 {
      100% {
        top: 110%;
        left: 33%;
      }
      }
      .confetti-18 {
      width: 3px;
      height: 1.2px;
      background-color: #263672;
      top: -10%;
      left: 28%;
      opacity: 0.6987014698;
      transform: rotate(344.977203343deg);
      -webkit-animation: drop-18 4.9120406131s 0.7363781485s infinite;
              animation: drop-18 4.9120406131s 0.7363781485s infinite;
      }
      
      @-webkit-keyframes drop-18 {
      100% {
        top: 110%;
        left: 40%;
      }
      }
      
      @keyframes drop-18 {
      100% {
        top: 110%;
        left: 40%;
      }
      }
      .confetti-19 {
      width: 5px;
      height: 2px;
      background-color: #d13447;
      top: -10%;
      left: 85%;
      opacity: 0.7712882055;
      transform: rotate(313.6778036985deg);
      -webkit-animation: drop-19 4.8281627067s 0.4098711479s infinite;
              animation: drop-19 4.8281627067s 0.4098711479s infinite;
      }
      
      @-webkit-keyframes drop-19 {
      100% {
        top: 110%;
        left: 88%;
      }
      }
      
      @keyframes drop-19 {
      100% {
        top: 110%;
        left: 88%;
      }
      }
      .confetti-20 {
      width: 5px;
      height: 2px;
      background-color: #263672;
      top: -10%;
      left: 29%;
      opacity: 1.2660082682;
      transform: rotate(40.8955802444deg);
      -webkit-animation: drop-20 4.9172941688s 0.9443089992s infinite;
              animation: drop-20 4.9172941688s 0.9443089992s infinite;
      }
      
      @-webkit-keyframes drop-20 {
      100% {
        top: 110%;
        left: 40%;
      }
      }
      
      @keyframes drop-20 {
      100% {
        top: 110%;
        left: 40%;
      }
      }
      .confetti-21 {
      width: 1px;
      height: 0.4px;
      background-color: #ffbf00;
      top: -10%;
      left: 20%;
      opacity: 1.2550087302;
      transform: rotate(338.8948650793deg);
      -webkit-animation: drop-21 4.7629932104s 0.1439447512s infinite;
              animation: drop-21 4.7629932104s 0.1439447512s infinite;
      }
      
      @-webkit-keyframes drop-21 {
      100% {
        top: 110%;
        left: 33%;
      }
      }
      
      @keyframes drop-21 {
      100% {
        top: 110%;
        left: 33%;
      }
      }
      .confetti-22 {
      width: 3px;
      height: 1.2px;
      background-color: #ffbf00;
      top: -10%;
      left: 47%;
      opacity: 1.3545188616;
      transform: rotate(19.5934939844deg);
      -webkit-animation: drop-22 4.4623566879s 0.8960714594s infinite;
              animation: drop-22 4.4623566879s 0.8960714594s infinite;
      }
      
      @-webkit-keyframes drop-22 {
      100% {
        top: 110%;
        left: 61%;
      }
      }
      
      @keyframes drop-22 {
      100% {
        top: 110%;
        left: 61%;
      }
      }
      .confetti-23 {
      width: 5px;
      height: 2px;
      background-color: #d13447;
      top: -10%;
      left: 57%;
      opacity: 0.8201461881;
      transform: rotate(339.4720329407deg);
      -webkit-animation: drop-23 4.4624008941s 0.6621418217s infinite;
              animation: drop-23 4.4624008941s 0.6621418217s infinite;
      }
      
      @-webkit-keyframes drop-23 {
      100% {
        top: 110%;
        left: 61%;
      }
      }
      
      @keyframes drop-23 {
      100% {
        top: 110%;
        left: 61%;
      }
      }
      .confetti-24 {
      width: 8px;
      height: 3.2px;
      background-color: #ffbf00;
      top: -10%;
      left: 26%;
      opacity: 1.1208219094;
      transform: rotate(7.598944429deg);
      -webkit-animation: drop-24 4.0938815491s 0.0073728694s infinite;
              animation: drop-24 4.0938815491s 0.0073728694s infinite;
      }
      
      @-webkit-keyframes drop-24 {
      100% {
        top: 110%;
        left: 35%;
      }
      }
      
      @keyframes drop-24 {
      100% {
        top: 110%;
        left: 35%;
      }
      }
      .confetti-25 {
      width: 4px;
      height: 1.6px;
      background-color: #ffbf00;
      top: -10%;
      left: 11%;
      opacity: 0.9684544461;
      transform: rotate(157.7704775465deg);
      -webkit-animation: drop-25 4.9270564218s 0.0139446999s infinite;
              animation: drop-25 4.9270564218s 0.0139446999s infinite;
      }
      
      @-webkit-keyframes drop-25 {
      100% {
        top: 110%;
        left: 20%;
      }
      }
      
      @keyframes drop-25 {
      100% {
        top: 110%;
        left: 20%;
      }
      }
      .confetti-26 {
      width: 7px;
      height: 2.8px;
      background-color: #d13447;
      top: -10%;
      left: 59%;
      opacity: 1.0068453446;
      transform: rotate(207.0693793038deg);
      -webkit-animation: drop-26 4.6925829219s 0.6476220524s infinite;
              animation: drop-26 4.6925829219s 0.6476220524s infinite;
      }
      
      @-webkit-keyframes drop-26 {
      100% {
        top: 110%;
        left: 73%;
      }
      }
      
      @keyframes drop-26 {
      100% {
        top: 110%;
        left: 73%;
      }
      }
      .confetti-27 {
      width: 7px;
      height: 2.8px;
      background-color: #263672;
      top: -10%;
      left: 65%;
      opacity: 1.0140140564;
      transform: rotate(135.169089324deg);
      -webkit-animation: drop-27 4.3329162256s 0.6243912778s infinite;
              animation: drop-27 4.3329162256s 0.6243912778s infinite;
      }
      
      @-webkit-keyframes drop-27 {
      100% {
        top: 110%;
        left: 71%;
      }
      }
      
      @keyframes drop-27 {
      100% {
        top: 110%;
        left: 71%;
      }
      }
      .confetti-28 {
      width: 8px;
      height: 3.2px;
      background-color: #ffbf00;
      top: -10%;
      left: 74%;
      opacity: 0.9456359752;
      transform: rotate(298.8195940327deg);
      -webkit-animation: drop-28 4.1812341847s 0.8522335441s infinite;
              animation: drop-28 4.1812341847s 0.8522335441s infinite;
      }
      
      @-webkit-keyframes drop-28 {
      100% {
        top: 110%;
        left: 80%;
      }
      }
      
      @keyframes drop-28 {
      100% {
        top: 110%;
        left: 80%;
      }
      }
      .confetti-29 {
      width: 3px;
      height: 1.2px;
      background-color: #d13447;
      top: -10%;
      left: 100%;
      opacity: 1.4218382923;
      transform: rotate(226.6775789446deg);
      -webkit-animation: drop-29 4.7338154247s 0.582231231s infinite;
              animation: drop-29 4.7338154247s 0.582231231s infinite;
      }
      
      @-webkit-keyframes drop-29 {
      100% {
        top: 110%;
        left: 110%;
      }
      }
      
      @keyframes drop-29 {
      100% {
        top: 110%;
        left: 110%;
      }
      }
      .confetti-30 {
      width: 1px;
      height: 0.4px;
      background-color: #263672;
      top: -10%;
      left: 46%;
      opacity: 1.4105988835;
      transform: rotate(18.6160479652deg);
      -webkit-animation: drop-30 4.6588967203s 0.1431496745s infinite;
              animation: drop-30 4.6588967203s 0.1431496745s infinite;
      }
      
      @-webkit-keyframes drop-30 {
      100% {
        top: 110%;
        left: 50%;
      }
      }
      
      @keyframes drop-30 {
      100% {
        top: 110%;
        left: 50%;
      }
      }
      .confetti-31 {
      width: 1px;
      height: 0.4px;
      background-color: #263672;
      top: -10%;
      left: 84%;
      opacity: 0.6397630954;
      transform: rotate(91.8694618262deg);
      -webkit-animation: drop-31 4.0408200514s 0.5923950507s infinite;
              animation: drop-31 4.0408200514s 0.5923950507s infinite;
      }
      
      @-webkit-keyframes drop-31 {
      100% {
        top: 110%;
        left: 99%;
      }
      }
      
      @keyframes drop-31 {
      100% {
        top: 110%;
        left: 99%;
      }
      }
      .confetti-32 {
      width: 4px;
      height: 1.6px;
      background-color: #263672;
      top: -10%;
      left: 68%;
      opacity: 0.6560114695;
      transform: rotate(193.4494269768deg);
      -webkit-animation: drop-32 4.3049756142s 0.4743989403s infinite;
              animation: drop-32 4.3049756142s 0.4743989403s infinite;
      }
      
      @-webkit-keyframes drop-32 {
      100% {
        top: 110%;
        left: 74%;
      }
      }
      
      @keyframes drop-32 {
      100% {
        top: 110%;
        left: 74%;
      }
      }
      .confetti-33 {
      width: 6px;
      height: 2.4px;
      background-color: #ffbf00;
      top: -10%;
      left: 44%;
      opacity: 0.5451613871;
      transform: rotate(255.6153444641deg);
      -webkit-animation: drop-33 4.8427819505s 0.3222313153s infinite;
              animation: drop-33 4.8427819505s 0.3222313153s infinite;
      }
      
      @-webkit-keyframes drop-33 {
      100% {
        top: 110%;
        left: 52%;
      }
      }
      
      @keyframes drop-33 {
      100% {
        top: 110%;
        left: 52%;
      }
      }
      .confetti-34 {
      width: 4px;
      height: 1.6px;
      background-color: #263672;
      top: -10%;
      left: 99%;
      opacity: 0.9232250998;
      transform: rotate(71.5764733389deg);
      -webkit-animation: drop-34 4.9193548811s 0.7216941572s infinite;
              animation: drop-34 4.9193548811s 0.7216941572s infinite;
      }
      
      @-webkit-keyframes drop-34 {
      100% {
        top: 110%;
        left: 112%;
      }
      }
      
      @keyframes drop-34 {
      100% {
        top: 110%;
        left: 112%;
      }
      }
      .confetti-35 {
      width: 7px;
      height: 2.8px;
      background-color: #ffbf00;
      top: -10%;
      left: 72%;
      opacity: 1.4430565815;
      transform: rotate(41.2308572709deg);
      -webkit-animation: drop-35 4.3896376563s 0.569709174s infinite;
              animation: drop-35 4.3896376563s 0.569709174s infinite;
      }
      
      @-webkit-keyframes drop-35 {
      100% {
        top: 110%;
        left: 76%;
      }
      }
      
      @keyframes drop-35 {
      100% {
        top: 110%;
        left: 76%;
      }
      }
      .confetti-36 {
      width: 1px;
      height: 0.4px;
      background-color: #263672;
      top: -10%;
      left: 5%;
      opacity: 0.5355754548;
     
     
      transform: rotate(196.4915682429deg);
      -webkit-animation: drop-36 4.970457015s 0.0953207518s infinite;
              animation: drop-36 4.970457015s 0.0953207518s infinite;
      }
      
      @-webkit-keyframes drop-36 {
      100% {
        top: 110%;
        left: 10%;
      }
      }
      
      @keyframes drop-36 {
      100% {
        top: 110%;
        left: 10%;
      }
      }
      .confetti-37 {
      width: 1px;
      height: 0.4px;
      background-color: #263672;
      top: -10%;
      left: 77%;
      opacity: 0.8304900024;
      transform: rotate(102.8451060165deg);
      -webkit-animation: drop-37 4.0148241662s 0.6055869842s infinite;
              animation: drop-37 4.0148241662s 0.6055869842s infinite;
      }
      
      @-webkit-keyframes drop-37 {
      100% {
        top: 110%;
        left: 84%;
      }
      }
      
      @keyframes drop-37 {
      100% {
        top: 110%;
        left: 84%;
      }
      }
      .confetti-38 {
      width: 8px;
      height: 3.2px;
      background-color: #ffbf00;
      top: -10%;
      left: 48%;
      opacity: 1.1838133044;
      transform: rotate(186.6903153563deg);
      -webkit-animation: drop-38 4.3042356252s 0.450503913s infinite;
              animation: drop-38 4.3042356252s 0.450503913s infinite;
      }
      
      @-webkit-keyframes drop-38 {
      100% {
        top: 110%;
        left: 62%;
      }
      }
      
      @keyframes drop-38 {
      100% {
        top: 110%;
        left: 62%;
      }
      }
      .confetti-39 {
      width: 2px;
      height: 0.8px;
      background-color: #263672;
      top: -10%;
      left: 67%;
      opacity: 1.0042686721;
      transform: rotate(282.6749878558deg);
      -webkit-animation: drop-39 4.2718114916s 0.6194635714s infinite;
              animation: drop-39 4.2718114916s 0.6194635714s infinite;
      }
      
      @-webkit-keyframes drop-39 {
      100% {
        top: 110%;
        left: 78%;
      }
      }
      
      @keyframes drop-39 {
      100% {
        top: 110%;
        left: 78%;
      }
      }
      .confetti-40 {
      width: 6px;
      height: 2.4px;
      background-color: #d13447;
      top: -10%;
      left: 80%;
      opacity: 1.0661462617;
      transform: rotate(60.149532163deg);
      -webkit-animation: drop-40 4.8341379685s 0.497138083s infinite;
              animation: drop-40 4.8341379685s 0.497138083s infinite;
      }
      
      @-webkit-keyframes drop-40 {
      100% {
        top: 110%;
        left: 93%;
      }
      }
      
      @keyframes drop-40 {
      100% {
        top: 110%;
        left: 93%;
      }
      }
      .confetti-41 {
      width: 4px;
      height: 1.6px;
      background-color: #263672;
      top: -10%;
      left: 20%;
      opacity: 1.1423114726;
      transform: rotate(352.9712641635deg);
      -webkit-animation: drop-41 4.0289290361s 0.5732317487s infinite;
              animation: drop-41 4.0289290361s 0.5732317487s infinite;
      }
      
      @-webkit-keyframes drop-41 {
      100% {
        top: 110%;
        left: 23%;
      }
      }
      
      @keyframes drop-41 {
      100% {
        top: 110%;
        left: 23%;
      }
      }
      .confetti-42 {
      width: 3px;
      height: 1.2px;
      background-color: #263672;
      top: -10%;
      left: 51%;
      opacity: 1.0382892374;
      transform: rotate(265.0190968876deg);
      -webkit-animation: drop-42 4.8676381016s 0.1485314688s infinite;
              animation: drop-42 4.8676381016s 0.1485314688s infinite;
      }
      
      @-webkit-keyframes drop-42 {
      100% {
        top: 110%;
        left: 59%;
      }
      }
      
      @keyframes drop-42 {
      100% {
        top: 110%;
        left: 59%;
      }
      }
      .confetti-43 {
      width: 6px;
      height: 2.4px;
      background-color: #d13447;
      top: -10%;
      left: 58%;
      opacity: 1.4692339499;
      transform: rotate(147.3454829063deg);
      -webkit-animation: drop-43 4.7595597891s 0.2119457261s infinite;
              animation: drop-43 4.7595597891s 0.2119457261s infinite;
      }
      
      @-webkit-keyframes drop-43 {
      100% {
        top: 110%;
        left: 67%;
      }
      }
      
      @keyframes drop-43 {
      100% {
        top: 110%;
        left: 67%;
      }
      }
      .confetti-44 {
      width: 1px;
      height: 0.4px;
      background-color: #263672;
      top: -10%;
      left: 43%;
      opacity: 0.624980912;
      transform: rotate(214.9397918259deg);
      -webkit-animation: drop-44 4.0870134315s 0.257924056s infinite;
              animation: drop-44 4.0870134315s 0.257924056s infinite;
      }
      
  
  /*   farmer_landing */
  .farmer_landing .big_font {
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 20px;
  }
  
  .farmer_landing .wp_img {
      width: 75%;
  }
  
  .farmer_landing .welcome-content {
          top: 10%;
  }
  
  .farmer_landing .middle_heading {
      font-size: 18px;
      color: #000000;
  }
    .farmer_landing .thnku-content {
      position: absolute;
      top: 10%;
      width: 100%;
      text-align: center;
  }
  
  .farmer-welcome .cashback_btn {
      margin-top: 2rem;
      text-align: center;
  }
  
  .cashback_btn a {
      width: 160px;
      display: block;
      margin: auto;
      background: #009933;
      padding: 10px 10px;
      border-radius: 10px;
      color: #ffffff !important;
      font-size: 16px;
      font-weight: 400;
  }
  
  .scan_success.show {
      height: 41% !important;
  }
  
  .scan_success .offcanvas-body {
      position: relative;
      width: 100%;
      overflow: visible;
  }
  
  .scan_success .cs_success_inner {
      position: absolute;
      top: -35px;
      width: 80%;
      left: 50%;
      transform: translate(-50%, 0px);
      text-align: center;
  }
  
  .scan_success .cs_success_inner .right_icon {
      width: 85px;
      border: 4px solid white;
      border-radius: 50%;
      margin-bottom: 20px;
  }
  
  .green_middle {
      font-size: 18px;
      color: #009933;
      font-weight: 500;
  }
  
  .f-awesome .middle_heading {
      margin-bottom: 5px;
  }
  
  .f-awesome .sweep-thnku-text ul li {
      margin-bottom: 10px;
  }
  
  .f-awesome .stars {
      width: 70px;
      margin-top: 10px;
  }
  
  .farmer_upiform .welcome-content {
          top: 40%;
          transform: translate(-50%, -50%);
      }
  
    .farmer_congratulation .cashback_img {
      position: absolute;
      bottom: 45px;
      z-index: 999;
      left: 50%;
      width: 50%;
      margin: auto;
      transform: translate(-50%, 0px);
  }
  
  .farmer_congratulation .sweep-gpay {
      margin-top: 30px;
  }
  
  .farmer_congratulation .sweep-logo {
      width: 80%;
  }
  
  .farmer_congratulation .welcome-content {
      top: 16%;
  }
  
  /*   farmer_landing */
      
  @media only screen and (max-width:1599px) {
      .welcome-content {
          top: 3%;
      }
      .sweep-thnku-text {
          margin-top: 3rem;
      }
  
  }
  
  
  @media only screen and (max-width:767px) {
      h1.sweep-welcome-heading {
          font-size: 35px;
      }
      p.sweep-welcome-para1, p.sweep-upi-para, .sweep-upi-subtext {
          font-size: 18px;
          margin-bottom: 2rem;
      }
      .sweep-thnku-text {
          margin-top: 2rem;
      }
      .sweep-input-wrapper input.form-control {
          width: 65%;
      }
      .sweep-input-wrapper select.form-select {
          width: 35%;
          margin-left: 5px;
      }
      
  
  }
  
  
  @media only screen and (max-width:376px) {
   .farmer_landing .big_font {
      font-size: 35px;
      font-weight: 400;
      margin-bottom: 15px;
  }
  .farmer_landing .wp_img {
      width: 70%;
  }
  
  .farmer_landing .welcome-content {
      top: 10%;
  }
  
  .cashback_img {
  
      bottom: 25px;
  
  }
  
  .farmer_upiform .welcome-content {
      top: 40%;
      transform: translate(-50%, -50%);
  }
  }
  
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px){
  
  .farmer_landing .welcome-content {
      top: 5%;
  }
  
    .farmer_landing .sweep-thnku-text {
          margin-top: 1rem;
      }
  
  .farmer_landing .big_font {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 17px;
  }
  
  .farmer_landing .wp_img {
      width: 50%;
  }
  
  .farmer_landing .sweep-thnku-bg {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      object-fit: contain;
  }
  
  main.sweep-thnku.farmer_landing {
      min-width: 320px;
      max-width: 365px;
    
  }
  
  .farmer_landing .sweep-logo {
      width: 140px;
  }
  
  .farmer_landing .sweep-welcome-heading {
      font-size: 30px;
  }
  
  .farmer_landing .middle_heading {
      font-size: 16px;
      color: #000000;
  }
  
  .farmer_congratulation .sweep-gpay {
      margin-top: 15px;
  }
  
  .farmer_congratulation p.sweep-welcome-para1, .farmer_congratulation p.sweep-upi-para {
      font-size: 18px;
      color: black;
      margin-bottom: 3rem;
  }
  
  .farmer_congratulation .cashback_img {
      bottom: 26px;
     
  }
  .farmer_upiform .welcome-content {
      top: 40%;
      transform: translate(-50%, -50%);
  }
  
   .farmer_upiform p.sweep-welcome-para1,  .farmer_upiform p.sweep-upi-para {
      font-size: 18px;
      color: black;
      margin-bottom: 2rem;
  }
  }
  
  


  .video-container {
    position: relative;
    width: 100%;
  }
  
  .video-frame {
    width: 100%;
  }
  
  .qr-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10%;
    width: 80%; /* Adjust frame width */
    height: 80%; /* Adjust frame height */
  }
  

  .whatsapp-button {
    color: green !important; 
    text-decoration: none; 
  }
  
  .whatsapp-button:hover {
    cursor: pointer; 
  }
  
  .whatsapp-icon {
    font-size: 5em; 
  }